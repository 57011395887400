import { Box, Card, Chip, Stack, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ApiService from '../../utils/ApiService';
import DashboardHeading from './DashboardHeading';
import { useNavigate } from 'react-router-dom';
import { loaderData } from '../../features/loader/loaderDashboard';
import { useSelector, useDispatch } from 'react-redux';
import { TagCloud } from 'react-tagcloud'
const TagsCloud = () => {
    const [allTags, setAllTags] = useState([]);
    const loading = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const searchList = useSelector((state) => (state.search));

    let obj = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    const navigate = useNavigate();
    const [tagCount, setTagCount] = useState(0);
    const userList = useSelector((state) => state?.user?.userListName)

    useEffect(() => {
        document.body.style.overflow = 'auto'
    }, [])

    useEffect(() => {
        console.log({searchList})
        if (searchList.searchList.length && searchList.searchList[0].files?.length) {
            let data = searchList.searchList;
            console.log({data});
            let tag = data.map(function (d) {
                return { id: d.id, name: { en: d.name.en }, pinned: d.pinned, value: d.name.en + "(" + d.files.length + ")", count: d.files.length * 20, files: d.files }
            }, data);
            setAllTags(tag);
            // setAllTags(searchList.searchList);
            setTagCount(searchList.searchList.length); // Corrected this line
        } else {
            if (searchList.searchList.length == 0 && searchList.searchValue == "") {
                tagApiCall()
            }
        }
    }, [searchList])

    function tagApiCall() {
        dispatch(loaderData(true));
        ApiService.get('tags', {userId: userList?.userId})
            .then((res) => {
                let data = res.data.data;
                if (data) {
                    let tag = data.map(function (d) {
                        return { id: d.id, name: { en: d.name.en }, pinned: d.pinned, value: d.name.en + "(" + d.files.length + ")", count: d.files.length * 20, files: d.files }
                    }, data);
                    setAllTags(tag);
                    setTagCount(data.length); // Corrected this line
                    dispatch(loaderData(false));
                }
            })
            .catch((error) => {
                dispatch(loaderData(false));
                console.log(error);
            });
    }
    const onWordClickHandler = (word) => {
        if (word.count > 0) {
            var start = (word.value.indexOf('('));
            var tagText = word.value.substring(0, start);
            const route = '/tags-folder/' + (tagText || '');
            navigate(route, { state: word });
        }
    }
    const getColor = (tag, maxSize, minSize) => {
        // Example: Set color based on tag count
        const color = tag.count === 0
            ? 'grey'
            : tag.count >= 1 && tag.count <= 20
                ? 'rgb(86, 66, 163)' : tag.count >= 20 && tag.count <= 50 ? 'rgb(29, 121, 249)'
                    : 'rgb(8, 118, 145)';
        return color;
    };
    // Define a function to compute tag sizes based on custom logic
    const computeTagSizes = () => {
        const minSize = 16; // Set your desired min font size
        const maxSize = 45; // Set your desired max font size

        const maxCount = Math.max(...allTags.map((tag) => tag.count));
        const minCount = Math.min(...allTags.map((tag) => tag.count));

        return allTags.map((tag) => ({
            ...tag,
            size: minSize + ((maxSize - minSize) * (tag.count - minCount)) / (maxCount - minCount),
            color: getColor(tag),
        }));
    };
    const customTags = computeTagSizes();

    return (
        <Box>
            <Card sx={{ p: 2, mb: 2, minHeight: '83vh' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <DashboardHeading title={`Tags`} numItems={tagCount} />
                    </Grid>
                </Grid>
                <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap', mt: 3 }}>
                    {loading.loaderValue ? (
                        obj.map((d, key) => {
                            return (
                                <Box key={key}>
                                    <Skeleton variant="rounded" width={100} height={40} sx={{ mt: '1rem', margin: '5px' }} />
                                </Box>
                            );
                        })
                    ) : (
                        <TagCloud
                            minSize={20}
                            maxSize={40}
                            // tags={allTags}
                            tags={customTags}
                            renderer={(value, tag) => (
                                <span key={value.value} style={{ width: value.value.length > 55 ? '55%' : '', wordBreak: value.value.length > 55 ? 'break-word' : '', color: getColor(value), fontSize: `${value.size}px` }}>
                                    {value.value}
                                </span>
                            )}
                            onClick={onWordClickHandler}
                        />
                        // allTags.map((key, index) => {
                        //     return (
                        //         <Chip
                        //             sx={{ margin: '5px', borderRadius: '8px' }}
                        //             label={`${key?.name?.en} (${key?.files.length})`}
                        //             key={index}
                        //             onClick={() => handleClick(key)}
                        //         />
                        //     );
                        // })
                    )}
                </Stack>
            </Card>
        </Box>
    );
};

export default TagsCloud;
