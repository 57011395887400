
import './App.css';
import './assets/styles/Customized.css';
import * as React from 'react';
import './assets/styles/Customized.css';
import './assets/styles/Theme.css';
import Sidebar from './layouts/sidebar/Sidebar';
import Footer from './layouts/footer/Footer';
import ThemeProvider from './theme';
import { useDispatch } from 'react-redux';
import "./App.css";
import ProfileImage from './assets/images/Avtaar.png';
import { setProfileImage } from './features/commonDataSlice/commonSlice';
import DashboardKM from './pages/KnowledgeManagement/Dashboard';
import { useEffect, useState } from 'react';
import { checkCallback, isLogin, removeUserToken } from './_helper/secureToken';
import { Navigate, Route, Routes, redirect, useLocation, useNavigate } from 'react-router-dom';
import { PrivateComponent } from './_services/PrivateComponent';
import Logout from './pages/Logout';
import Login from './Login/Login';
import SSOLogin from './Login/SsoLink';
import Register from './Login/Register';
import ResetPassword from './Login/ResetPassword';
import ForgotPassword from './Login/forgotPassword';
import RegisterView from './Login/RegisterView';
const windowObject = window;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function TeamSwitchComponent() {

  const [component, setComponent] = useState([]);
  let navigate = useNavigate()
  useEffect(() => {
    setComponent(PrivateComponent());
  }, []);
  let query = useQuery();
  let authToken = query.get("token");
  let appToken = query.get("app");
  let authType = query.get("authType");
  let callback = query.get("callback");
  let ssoToken = query.get("ssoToken");
  let location = useLocation();

  console.debug("quesry", query)
  // let loginauthenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/login?callback=${window.location.href}`;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setProfileImage(ProfileImage))
  }, []);

 
  return (
    <>
      <Routes>

    {component.map((u, i) =>
          checkCallback(authToken, appToken, authType, callback, ssoToken) ?
            <Route key={i} path={u.url} element={u.page} />
            :
            //  redirect("/login")
            //  window.location.assign("/login")
            <Route key={i}
            path="*"
            element={<Navigate to="/login" replace />}
        />
            // <Route key={i} path="/" element={<Login/>}/> 
            // console.debug("ISLOGIN VALUE:", checkCallback(authToken, appToken, authType, callback, ssoToken))
           
        ) 
    }
        <Route exact path="/login" element={<Login/>}/> 
       <Route path="/sso-link" element={<SSOLogin/>} />
       <Route path="/register" element={<Register/>} />
       <Route path="/reset-password" element={<ResetPassword/>} />
       <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
       <Route exact path="/register-view" element={<RegisterView />} ></Route>

                
            
        
        <Route path="/logout" element={<Logout />} />
          {/* // <Navigate to="/login" replace />} /> */}


      </Routes>
    </>)
}

export default TeamSwitchComponent;



