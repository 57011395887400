import axios from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
// const BASE_URL = 'https://gatewayapi.eela.tech/api';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      comapnyLog: "api/v1/company/1",
      folderpermission: "api/v1/folderpermission",
      tags: "api/v1/tags",
      renamefileManager: "api/v1/rename/file-manager",
      filemanagerActivity: "api/v1/filemanager-activity",
      categories: "api/v1/categories",
      category: "api/v1/category",
      recentTags: "api/v1/user-tag",
      search: "api/v1/file-manager-search",
      tagCloud: "api/v1/tags-cloud",
      fileManager: "api/v1/file-manager",
      permission: "api/v2/user-permissions",
      fileDetail:"api/v1/file-details",
      // folderDelete: "foldersoftdelete",

      // -------------

      fileFolderSoftDelete: "api/v1/filefolder-multiple-delete",
      fileFolderRestore: "api/v1/multiplerestore",

      // ---------- trash page apis end point ------------

      fileArchive: "api/v1/folder-file-retrieve",
      fileRestore: "api/v1/file-manager/restore",
      folderRestore: "api/v1/folderrestore",
      deleteTrash: "api/v1/folderfile-permanent-delete",
      permanentDelete: 'api/v1/file-manager/force-delete/',
      multiplefilefolderdelete: 'api/v1/multiple-filefolder-delete',
      multiplefiledelete: 'api/v1/multiple-file-delete',
      multiplefolderdelete: 'api/v1/multiple-folder-delete',

      // ----------- Filter Category -------
      // filterCategory: "v1/filter",
      // userInfo: "v1/userinfo",
      getpinned: "api/v1/getpinned",
      filefolderPin: 'api/v1/pinned-file-folder',
      unpinned: 'api/v1/unpinned',
      tagSearch: "api/v1/search-tag",
      filterCategory: "api/v1/filter",
      userInfo: "api/v1/userinfo",

      // --------------Move File----------------
      moveFile: 'api/v1/move/file-manager'

    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    const responseType = endpointKey === 'fileManager' ? 'blob' : 'json'; // Set the responseType based on endpointKey
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
        responseType
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
}



export default new APIService(BASE_URL);
