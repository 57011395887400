import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Grid, Checkbox, FormGroup, FormControlLabel, IconButton, Popover } from '@mui/material';
import checkboxHelper from './checkboxHelper';
import MyContext from './Mycontext';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import { userPermissions } from './Permission/required.permission';
import DashboardKmTools from './DashboardKmTools';
import imagesrc from "../../assets/images/word.png"
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ImageType from './ImageType';
import longPressHelper from '../../_helper/longPressDetection';
import PushPinIcon from '@mui/icons-material/PushPin';
import unpin from '../../assets/images/KM-Manager/unpin.svg';
import PinUnpin from './PinUnpin';
import MenuBar from './menuBar';
import { setPageNo, setScrollFlag } from '../../features/dashboardData/dashboardData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { removeSearch, removeSearchList } from '../../features/headerSearch/headerSearch';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import throttle from '../../utils/throttle';
import Can from './Permission/Can';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 2,
  },
}));

const DashboardGridView = ({ isTrash, isPinned }) => {
  const [showFullTooltip, setShowFullTooltip] = useState(false);
  const [headerIntermidiateStatus, setHeaderIntermidiateStatus] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader)
  const userPermissionsListRedux = useSelector((state) => (state.permission));
  const [userPermissionsList, setUserPermissionsList] = useState([]);
  const dashboardScroll = useSelector((state) => (state.dashboardScroll));
  const { pinUnpinHandler } = PinUnpin();
  const currentRoute = useSelector((state) => (state.routing.currentRoute));
  let obj = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const methodsAndState = useContext(MyContext);
  const searchList = useSelector((state) => (state.search));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverContent, setPopoverContent] = useState("");
  const [popOverContentDesc, setPopoverContentDesc] = useState("");
  const options = { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true };
  // const debaunceThrottle = throttle();
  const [disablePinBtn, setDisablePinBtn] = useState(false);
  // const throttleStart = (e,key) => {
  //   debaunceThrottle.throttle((e) => {
  //     pinUnpinSingleHandler(e, key, true)
  //   }, 1000)
  // }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget ?? event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   if(disablePinBtn){
  //       setTimeout(() => {
  //           setDisablePinBtn(false);
  //       }, 1000);
  //   }
  // }, [disablePinBtn])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (methodsAndState.dashboardList.length) {
      methodsAndState.checkboxGridHelper.init(
        methodsAndState.dashboardList,
        methodsAndState.setSelectedItems,
        methodsAndState.setHeaderCheckStatus,
        methodsAndState.setShowCode,
        // setHeaderIntermidiateStatus
        false
      );
    }
  }, [methodsAndState.dashboardList]);

  useEffect(() => {
    if (userPermissionsListRedux.permissionList) {
      setUserPermissionsList(userPermissionsListRedux.permissionList)
    }
  }, [userPermissionsListRedux])

  useEffect(() => {
    if (methodsAndState.selectedItems.size == 0) {
      methodsAndState.checkboxGridHelper.clearSelection()
    }
  }, [methodsAndState.selectedItems])

  const pinUnpinSingleHandler = (e, data, noClear) => {
    e.stopPropagation();
    setDisablePinBtn(true);
    selectionCheckboxClear(data, noClear)
    pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, data.pinned ? "Unpin" : "Pin", methodsAndState.setSelectedItems, methodsAndState.setSnackBar, methodsAndState.setDashboardList, setDisablePinBtn);
    if(isPinned && data.deletedAt){
      methodsAndState.setSnackBar({ dataLoad: true, message: "Removed from Pinned Section", severity: "error" })
    }
  }
  const selectionCheckboxClear = (data, noClear) => {
    if (!noClear) {
      methodsAndState.checkboxGridHelper.clearSelection()
      methodsAndState.checkboxGridHelper.checkboxListener(data.id);
    }
    methodsAndState.selectedItems.clear()
    methodsAndState.selectedItems.add(data.id)
  }

  const checkBoxHandler = (data) => {
    methodsAndState.checkboxGridHelper.checkboxListener(data.id);
    methodsAndState.setCheckedData(data);
    methodsAndState.setSelectedId({
      id: data.id,
      value: 'checkbox',
      type: data.type,
      name: data.name,
      delete: 'notall',
    });
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    methodsAndState.checkboxGridHelper.setIntermidiateStatus(false);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };

  }, []);

  useEffect(() => {
    if (isMobile) {
    }
  }, [isMobile]);


  const [longPressDetection, setLongPressHelper] = useState(longPressHelper());

  useEffect(() => {
    let longPressDetectionCF = longPressDetection;
    longPressDetectionCF.callback = (key) => {
      checkBoxHandler(key)
    }
    longPressDetectionCF.callbackTouchEnd = (key) => {
      if (methodsAndState.selectedItems.size != 0) {
        checkBoxHandler(key)
      }
    }
    setLongPressHelper(longPressDetectionCF);
  }, [])

  const openFolder = (data) => {
    if(isPinned && data.disable){
      return;
    }
    if (data.type === 'directory' && window.location.hash != "#/trash") {
      const paramsid = typeof data?.id === 'string' && data?.id.includes('_') ? data?.id.split('_')[1] : data?.id;
      methodsAndState.setSelectedId({ id: data.id, value: 'folder' });
      let state = [...methodsAndState.breadCrumb, data];
      const route = '/folder/' + paramsid;
      navigate(route, { state: state });
      if (currentRoute == '#/pinned') {
        localStorage.setItem('myContextState', JSON.stringify(state));
      }
      methodsAndState.setBreadCrumb(state);
      methodsAndState.setSelectedItems(new Set([]))
      dispatch(setScrollFlag(false))
      dispatch(setPageNo(0))
      // dispatch(removeSearch(''))
    }
  };

  const fetchData = () => {
    if (methodsAndState?.metaData?.last_page > methodsAndState?.metaData?.current_page && searchList?.searchList?.length == 0) {
      dispatch(setScrollFlag(true))
      methodsAndState.apiCallingDashboard(dashboardScroll.pageNo, true)
    }
  }

  console.debug("userPermissions", userPermissions)


  const buttonSX = {
    '&': { color: '#00000087' },
    '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
  }
  return loading.loaderValue ? (
    <Grid container spacing={2} >
      {obj.map((d, key) => {
        return (
          <Grid key={key} item sx={{ m: '2rem' }}>
            <Skeleton variant="rounded" width={110} height={110} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Box>
      <Box>
        <InfiniteScroll
          dataLength={methodsAndState.dashboardList.length}
          next={fetchData}
          hasMore={true} // Replace with a condition based on your data source
          loader={(methodsAndState?.metaData?.last_page > methodsAndState?.metaData?.current_page && dashboardScroll.scrollFlag) ? <Grid container spacing={2} >
            {[1, 2, 3, 4, 5, 6, 7].map((d, key) => {
              return (
                <Grid key={key} item sx={{ m: '2rem' }}>
                  <Skeleton variant="rounded" width={110} height={110} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Grid>
              );
            })}
          </Grid> : false}
        >
          <Grid container spacing={2} sx={{ mt: { xs: 0, md: 0 } }}  >
            {/* <Grid container spacing={2} sx={{ mt: { xs: isTrash ? 0 : '3.5rem', md: 0 }, height: isTrash ? '70vh' : 'unset', overflowY: isTrash ? 'scroll' : 'unset' }}> */}
            {methodsAndState.dashboardList.length ? methodsAndState.dashboardList.map((key, index) => (
              <Grid item key={index} xs={6} sm={3} md={2} lg={1.5} xl={1.5} >
                <Box
                  onTouchStart={(event) => {
                    if(isPinned && key.deletedAt){
                      return;
                    }
                    if (isMobile && methodsAndState.selectedItems.size) {
                      checkBoxHandler(key);
                    } else {
                      longPressDetection.touchstart(event, key);
                    }
                  }}
                  onTouchMove={longPressDetection.touchmove}
                  onTouchEnd={() => {
                    if(isPinned && key.deletedAt){
                      setPopoverContent("This " + (key.type === 'directory' ? 'folder' : 'file') + ' was deleted by ' + key.deletedBy.charAt(0).toUpperCase() + key.deletedBy.slice(1));
                      setPopoverContentDesc(' on ' + new Date(key.deletedAt).toLocaleString("en-US", options));
                      return;
                    }
                    longPressDetection.touchend(key);
                  }}
                  onClick={(event) => {
                    if(isPinned && key.deletedAt){
                      setPopoverContent("This " + (key.type === 'directory' ? 'folder' : 'file') + ' was deleted by ' + key.deletedBy.charAt(0).toUpperCase() + key.deletedBy.slice(1));
                      setPopoverContentDesc(' on ' + new Date(key.deletedAt).toLocaleString("en-US", options));
                      handleClick(event);
                    }
                  }}
                  sx={{
                    mr: { sx: '1rem' },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '8rem',
                    height: '10.5rem',
                    padding: '0.2rem',
                    borderRadius: '0.5rem',
                    '&:hover': {
                      border: '1px solid #BCD3F3',
                      background: '#EEF6FF',
                      '& >  div': {
                        visibility: 'visible',
                      },
                    }, '&.present': {
                      border: '1px solid #BCD3F3',
                      background: '#EEF6FF',
                      '&  > div': {
                        visibility: 'visible',
                      },
                    }, '&.removed': {
                      opacity: 0.6
                    }, '&.removed .MuiCheckbox-root': {
                      visibility: 'hidden'
                    }, '&.removed > div:first-of-type > div > div': {
                      visibility: 'hidden'
                    }
                  }
                  }
                  className={methodsAndState.selectedItems.has(key.id) ? ' present ' : '' + isPinned && key.deletedAt ? ' removed ' : '' + (key.name == undefined ? " disabled-container " : "")}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1, visibility: 'hidden' }}>
                 {console.debug("userPermissions", userPermissions)}
                  <Can
                        userPermissions={userPermissionsList}
                        requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
                  yes={ <Checkbox
                      onClick={() => {
                        if(isPinned && key.deletedAt){
                          return;
                        }
                        if
                          (!isMobile || methodsAndState.selectedItems.size == 0) { checkBoxHandler(key) }
                      }}
                      checked={methodsAndState.selectedItems.has(key.id)}
                    /> } />
                    <Box sx={{ mt: '0.2rem', display: 'flex' }}
                      onClick={(event) => {
                        if(isPinned && key.deletedAt){
                          return;
                        }
                        selectionCheckboxClear(key)
                        }} >
                          {(!isPinned || !key.deletedAt) ? (
                            <>
                              {window.location.hash != "#/trash" && (
                              <HtmlTooltip title={key.pinned ? 'Unpin' : 'Pin'}>
                                <IconButton onClick={(e) => {
                                  // debaunceThrottle.throttle(() => {
                                    pinUnpinSingleHandler(e, key, true)
                                  // }, 1000)
                                }}
                                  disabled={disablePinBtn}
                                  sx={buttonSX} aria-label="pin">
                                  {window.location.hash == "#/trash" ? "" : key.pinned || window.location.hash == "#/pinned" ? <img src={unpin} /> : <PushPinIcon />}
                                </IconButton></HtmlTooltip>
                              )}
                            </>
                          ) : (<>
                              <HtmlTooltip title={'Remove from pinned section'}>
                                <IconButton onClick={(e) => {
                                  // debaunceThrottle.throttle(() => {
                                    pinUnpinSingleHandler(e, key, true)
                                  // }, 1000)
                                }}
                                  disabled={disablePinBtn}
                                  sx={buttonSX} aria-label="pin">
                                  {<DeleteOutlineIcon />}
                                </IconButton></HtmlTooltip>
                          </>)}
                        {/* {isPinned && key.deletedAt && ( */}
                          <MenuBar data={methodsAndState.checkedData} value={true} />
                        {/* )} */}
                    </Box>
                  </Box>
                  <Box
                    onClick={() => {
                      if(isPinned && key.deletedAt){
                        return;
                      }
                      if (!isMobile || methodsAndState.selectedItems.size == 0) {
                        openFolder(key)
                      }
                    }}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ImageType data={key} isPinned={isPinned} />
                  </Box>
                  <Box sx={{ width: '7rem', padding: '0.2rem' }}>
                    <Typography variant="body2" sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                      {key.name != undefined ? <HtmlTooltip title={key.name}>
                        <span>
                          {key.name.length > 15
                            ? key.name.substring(0, 15) + '...'
                            : key.name}
                        </span>
                      </HtmlTooltip> : "--"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )) :
              <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh", width: "100%" }} >No Data Found</Typography>
            }
          </Grid>
        </InfiniteScroll>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, whiteSpace: 'pre-wrap' }}>
          <Box>
            <Typography>{popOverContent}</Typography>
          </Box>
          <Box>
            <Typography variant="caption">{popOverContentDesc}</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DashboardGridView;
