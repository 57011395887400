import { Box, Button, Card, Grid, Stack } from '@mui/material';
import React, { useRef } from 'react';
import DashboardHeading from './DashboardHeading';
import DashboardTools from './DashboardTools';
import MyContext from './Mycontext';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DashboardGridandTree from './DashboardGridandTree';
import { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { openDialog } from '../../features/dialogBox/dialogBoxSlice';
import { useDispatch, useSelector } from 'react-redux';
import SharedDialog from '../../features/dialogBox/dialogBox';
import DeleteDialog from './DialogKM/DeleteDialog';
import RenameDialog from './DialogKM/RenameDialog';
import TransitionsSnackbar from './Constantfile/Snackbar';
import SharedDrawer from '../../features/drawers/Drawer';
import FileDashboardKM from './DrawerDialogKM/FileDashboardKM';
import { openDrawer } from '../../features/drawers/drawerSlice';
import DashboardKmTools from './DashboardKmTools';
import ApiService from '../../utils/ApiService';
import checkboxHelper from './checkboxHelper';
import { setCategoryDataList } from '../../features/commonDataSlice/categoryData';
import MoveDialog from './DialogKM/MoveDialog';

const TagAttachedFolder = () => {
    const dispatch = useDispatch();
    let { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [showGridView, setShowGridView] = useState(true);
    const [showTreeView, setShowTreeView] = useState(false);
    const [tagsFlag, setTagsFlag] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const [dashboardList, setDashboardList] = useState(location?.state?.files.map(v => {
        v.modifyBy = v.modifiedBy;
        return v;
    }))
    const [selectedItems, setSelectedItems] = useState(new Set([]));
    const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
    const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
    const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
    // const [categoryList, setCategoryList] = useState([]);
    const [isFixedTop, setisFixedTop] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
    const [checkedData, setCheckedData] = useState();
    const [checkboxGridHelper, setCheckboxGridHelper] = useState(
        checkboxHelper()
    );
    const [sortBy, setSortBy] = useState(''); // Initialize the sorting order to 'asc'
    var sortName = ''
    const categoryListData = useSelector((state) => (state.category));
    // const [dashboardList, setDashboardList] = useState([])
    const handleGridViewClick = () => {
        setShowGridView(true);
        setShowTreeView(false);
    };
    const openDrawerPublish = (id) => {
        setEditFlag({ flag: false, value: "edit" })
        dispatch(openDrawer(id));
    }

    const handleTreeViewClick = () => {
        setShowGridView(false);
        setShowTreeView(true);
    };
    const handleButtonClick = () => {
        setShowCode(!showCode);
    };
    const openDialogPublish = (id) => {
        dispatch(openDialog(id));
    }

    const sortByNameType = (value) => {
        sortName = sortBy == '' || sortBy == 'desc' ? 'asc' : 'desc'
        setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
        let sortData = dashboardList
        if (sortName == 'asc') {
            sortData.sort(function (a, b) {
                return a[value].localeCompare(b[value])
            });
        } else {
            sortData.sort(function (a, b) {
                return b[value].localeCompare(a[value])
            });
        }
        setDashboardList(sortData)

    }


    const methodsAndState = {
        sortByNameType,
        handleGridViewClick,
        handleTreeViewClick,
        showGridView,
        showTreeView,
        handleButtonClick,
        tagsFlag,
        dashboardList,
        setDashboardList,
        setSelectedItems,
        selectedItems,
        setSelectedId,
        selectedId,
        openDialogPublish,
        setSnackBar,
        snackBar,
        // setCategoryList,
        // categoryList,
        setEditFlag,
        editFlag,
        openDrawerPublish,
        isFixed, checkboxGridHelper,
        setIsFixed, showCode, setShowCode, setHeaderCheckStatus, headerCheckStatus,
        checkedData, setCheckedData
    }
    const headerRef = useRef();
    const element = document.querySelector('#headerWidthListner');
    const [widthHeader, setWidthHeader] = useState("100%");
    // const listener = useRef(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.target.getBoundingClientRect();
                setWidthHeader((parseInt(width) - 10) + 'px');
            }
        });
        observer.observe(headerRef.current);

        return () => {
            observer.disconnect();
        };
    }, [element]);

    const numItemsPresent = dashboardList.length;
    useEffect(() => {
        const handleScroll = () => {
            const positionCheckfix = document.getElementById('checkFix').getBoundingClientRect().top;
            setisFixedTop(76 > positionCheckfix);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (categoryListData.categoryList.length == 0) {
            ApiService.get('category').then(res => {
                let response = res.data.data
                if (response) {
                    // setCategoryList(response)
                    dispatch(setCategoryDataList(response))
                }
            })
        }
    }, [categoryListData])

    const headerTags = () => {
        return (
            <Box sx={{ display: 'flex' }}>
                <Box>
                    <Button onClick={() => navigate(-1)}
                        startIcon={<ArrowBackIosIcon />}>
                    </Button>
                </Box>
                <Box>
                    <DashboardHeading title={location?.state?.name?.en} numItems={numItemsPresent} />
                </Box>
            </Box>
        )
    }
    const upperDiv = document.getElementById('sticky');
    // Calculate the height of the upper div
    const upperDivHeight = upperDiv?.clientHeight - 20;
    return (
        <MyContext.Provider value={methodsAndState}>
            <Box id="checkFix">
                <Box>
                    <Card sx={{ mb: 2, minHeight: '83vh' }} id="headerWidthListner" ref={headerRef}>
                        {/* <Box id="headerWidthListner" ref={headerRef} sx={{ p: 2, my: 2, minHeight: '83vh' }}> */}
                        <Box id="sticky" sx={{ paddingBottom: selectedItems.size ? '12px !important' : '12px !important', width: widthHeader, }}>
                            <Box sx={{ flexWrap: 'wrap', width: "100%" }}>
                                <Stack sx={{ backgroundColor: '#ffffff' }}>
                                    <Grid container spacing={2} sx={{ mt: 0 }}>
                                        <Grid item xs={12} sm={8} sx={{ paddingTop: "0px !important" }} >
                                            {!isFixedTop ? (selectedItems.size === 0 && headerTags()) : selectedItems.size === 0 && headerTags()}
                                            <DashboardKmTools />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ zIndex: '10000 !important', paddingTop: "0px !important" }}>
                                            <Box sx={{ marginTop: isFixedTop ? '0rem' : '' }}>
                                                <DashboardTools />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Box>
                        {/* </Box> */}
                        {/* <Box className="gridTabularView" sx={{ mt: "2rem" }}> */}
                        <Box className="gridTabularView" sx={{ mt: upperDivHeight + "px" }}>
                            {/* <Box className="gridTabularView" sx={{ mt: { xl: "3rem", lg: "3rem", md: "4rem", sm: "3rem", xs: selectedItems.size ? "9rem" : '5rem' } }}> */}
                            <Grid item xs={12} sm={12}>
                                <DashboardGridandTree />
                            </Grid>
                        </Box>
                    </Card>
                    <SharedDrawer id="editFile">
                        <FileDashboardKM />
                    </SharedDrawer>
                    <SharedDialog id='deleteDialog'>
                        <DeleteDialog />
                    </SharedDialog>
                    <SharedDialog id='renameDialog'>
                        <RenameDialog />
                    </SharedDialog>
                    <SharedDialog id="moveDialog">
                        <MoveDialog />
                    </SharedDialog>
                    <TransitionsSnackbar />
                </Box>
            </Box>
        </MyContext.Provider >
    );
};

export default TagAttachedFolder;