import React, { useContext, useState } from 'react';
import ApiService from '../../utils/ApiService';
import { Chip, Stack, Button, useMediaQuery, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeSearch, searchData, searchValueSet } from '../../features/headerSearch/headerSearch';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from './Mycontext';
import { loaderData } from '../../features/loader/loaderDashboard';
// import TagsCloud from './TagsCloud';
import { useTheme } from '@mui/material/styles';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
const RecentTags = () => {
  const [chipColor, setChipColor] = useState(null);
  const dispatch = useDispatch();
  const searchValueState = useSelector((state) => state.search?.searchValue);
  const methodsAndState = useContext(MyContext);
  const recentTags = useSelector((state) => (state.recentTag?.recentTags));
  // const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTagsCount, setRemainingTagsCount] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleMoreTag = () => {
    navigate('/tags');
    dispatch(setCurrentRoute('/tags'));

  };

  // Event handler for when the chip is clicked
  const handleColorHandler = (data, index) => {
    if (chipColor != null && chipColor === index) {
      setChipColor(null);
      dispatch(removeSearch(''));
    } else {
      dispatch(loaderData(true))
      setChipColor(index);
      dispatch(searchValueSet(data.name.en));
    }
  };

  useMemo(() => {
    if (searchValueState === '') {
      setChipColor(null);
    }
  }, [searchValueState]);
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXl = useMediaQuery(theme.breakpoints.down('xl'));

  // Calculate character limits based on screen size
  const getCharacterLimit = () => {
    if (isXs) return 10;
    if (isSm) return 15;
    if (isMd) return 30;
    if (isLg) return 40;
    if (isXl) return 60;
    return 70; // Default for larger screens
  };

  // Filter and display visible tags based on character limits
  const visibleTags = useMemo(() => {
    const characterLimit = getCharacterLimit();
    let totalChars = 0;
    const visibleTagsArray = [];
      if (recentTags.length && recentTags[0] != 'nodata') {
      for (const tag of recentTags) {
        if (totalChars + tag.name.en.length <= characterLimit) {
          visibleTagsArray.push(tag);
          totalChars += tag.name.en.length;
        } else {
          break;
        }
      }
      return visibleTagsArray;
    } else {
      return []
    }
  }, [recentTags, isXs, isSm, isMd, isLg, isXl]);
  return (
    <Stack id={"tagsContainer"} direction="row" spacing={1}>
      {recentTags[0] != 'nodata' || visibleTags.length ? visibleTags.map((key, index) => (
        <Chip
          label={`${key?.name?.en}`}
          key={index}
          color={chipColor === index ? 'primary' : 'default'}
          onClick={() => handleColorHandler(key, index)}
          sx={{ borderRadius: '5px' }}
        />
      )) : <Typography>Once user will generate tags it will appears here.</Typography>}
      {/* {remainingTagsCount > 0 && (
        <Button
          onClick={handleMoreTag}
          sx={{ mx: 1, borderRadius: '8px' }}
        >
          +{''}{remainingTagsCount}  more
        </Button>
      )} */}

      {visibleTags.length ? visibleTags.length < recentTags.length && (
        <Button onClick={handleMoreTag} sx={{ mx: 1, borderRadius: '8px' }}>
          +{recentTags.length - visibleTags.length} more
        </Button>
      ):null}
    </Stack>
  );
};

export default RecentTags;
