import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../features/dialogBox/dialogBoxSlice';
import ApiService from '../../../utils/ApiService';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import MyContext from '../Mycontext';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import checkboxHelper from '../checkboxHelper';

export default function DeleteDialog() {
    const methodsAndState = React.useContext(MyContext);
    const [checkedValue, setCheckedValue] = useState(null)
    const [flag, setFlag] = useState(false)
    const dispatch = useDispatch();
    const userList = useSelector((state) => state?.user?.userListName);

    let uniqueArray = [];
    const closeDialogPublish = (id) => {
        dispatch(closeDialog(id));
    }
    function deleteResponse(folderIds, fileIds) {
        const paramsfolderIds = Array.isArray(folderIds) ?
            folderIds.map(v => (typeof v === 'string' && v.includes('_')) ? v.split('_')[1] : v)
            : (typeof folderIds === 'string' && folderIds.includes('_')) ? folderIds.split('_')[1] : folderIds;
        const paramsfileIds = Array.isArray(fileIds) ?
            fileIds.map(v => (typeof v === 'string' && v.includes('_')) ? v.split('_')[1] : v)
            : (typeof fileIds === 'string' && fileIds.includes('_')) ? fileIds.split('_')[1] : fileIds;
        let params = {
            ids: paramsfolderIds,
            fids: paramsfileIds,
            userId: userList?.userId
        }
        let checkedValue = checkboxHelper().snackBarMessageHandler(fileIds, folderIds)
        setFlag(true)
        ApiService.delete('fileFolderSoftDelete', params).then(res => {
            setFlag(false)
            methodsAndState.setSelectedItems(new Set([]))
            methodsAndState.setSnackBar({ dataLoad: true, message: `${checkedValue?.fileText + checkedValue?.folderText + " has been successfully deleted."}`, severity: "info" })
            let mergefolderfile = [...folderIds, ...fileIds]
            let deleteData = methodsAndState.dashboardList.filter(v => !mergefolderfile.includes(v.id))
            methodsAndState.setDashboardList(deleteData)
            methodsAndState.updateMetaData( -1 * mergefolderfile.length );
            methodsAndState.setSelectedId({ id: '0', value: "", name: "", type: "" })
            closeDialogPublish('deleteDialog')
        }).catch((error) => {
            setFlag(false)
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    }

    const deleteHandler = () => {
        if (checkedValue != null) {
            let folderIds = []
            let fileIds = []
            const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
            for (const item of matchingData) {
                if (item.type === "directory") {
                    folderIds.push(item.id);
                } else {
                    fileIds.push(item.id);
                }
            }
            deleteResponse(folderIds, fileIds)

        }
    }
    return (
        <Box pb="1rem">
            <DialogTitle id="alert-dialog-title">
                {"Delete"}
            </DialogTitle>
            <DialogContent sx={{ padding: '16px 24px' }}>
                <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                    Are you sure you want to delete?
                    {/* {methodsAndState.dynamicDeleteMessage} */}
                </DialogContentText>
                <FormGroup sx={{ padding: '20px 0px 0px 0px' }}>
                    <FormControlLabel required
                        control={<Checkbox
                            onChange={(e) => setCheckedValue(e.target.checked)}
                            sx={{ mb: 3 }}
                        />}
                        label="I Understand that deleting file or folder from KM is not permanent and send this item to trash." />
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button onClick={() => closeDialogPublish('deleteDialog')} disabled={!flag ? false : true}>
                    CANCEL
                </Button>
                {flag == false ? <Button onClick={deleteHandler} color='primary' variant="contained" disabled={checkedValue ? false : true} >YES, DELETE</Button>
                    : <Button color='primary' variant="contained"> <CircularProgress color={'success'} size="20px" /></Button>}
            </DialogActions>
        </Box>
    );
}