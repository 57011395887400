import { configureStore } from '@reduxjs/toolkit';
import drawerReducer from './features/drawers/drawerSlice';
import dialogReducer from './features/dialogBox/dialogBoxSlice';
// import wheelSlice from './features/wheel/wheelSlice';
import uploadSlice from './features/uploadPic/uploadSlice';
import commonSlice from './features/commonDataSlice/commonSlice';
import sidebarSelectionSlice from './features/sidebarRouting/sidebarSelectionSlice';
import headerSearch from './features/headerSearch/headerSearch';
import permissionData from './features/permission/permissionData';
import LoaderDashboard from './features/loader/loaderDashboard';
import dashboardData from './features/dashboardData/dashboardData';
import recentTagsData from './features/dashboardData/recentTagsData';
import userInfo from './features/userInfoStore/userSlice';
import categoryData from './features/commonDataSlice/categoryData';


const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    dialog: dialogReducer,
    // wheel: wheelSlice,
    profile: commonSlice,
    imageCropper: uploadSlice,
    routing: sidebarSelectionSlice,
    search: headerSearch,
    permission: permissionData,
    loader: LoaderDashboard,
    dashboardScroll: dashboardData,
    recentTag: recentTagsData,
    user: userInfo,
    category:categoryData
  },
});

export default store;
