export const authentication = {
  ssoAuthType: 'sso',
  selfAuthType: 'self',
};

// cookies constant
export const user = {
  user: 'user2',
  userFullName: "userFullName",
  userEmail: "userEmail",
  tempUser: 'tempUser',
  selfToken: 'selfToken',
  ssoToken: 'ssoToken',
  authType: 'authType',
  appToken: 'appToken',
  tempSsoToken: 'tempSsoToken',
  defaultCurrentAccountStatus: 'default',
};