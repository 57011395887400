import React from 'react';
import { removeUserToken } from '../_helper/secureToken';
import { Navigate } from 'react-router-dom';
function Logout(props) {
    // let logoutauthenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.href}`;
 let logoutauthenticationUrl = "/login";

    removeUserToken()
   return <Navigate to="/login" replace />
}

export default Logout;