import ApiService from "../../utils/ApiService";
import { useState } from 'react';

const openDownload = () => {

    const downloadFile = (dataFile, selectedData, setOpenText,setDownloadText) => {

        const canOpen = ['image/png', 'image/jpg', 'image/jpeg', ];
        const paramsid = typeof selectedData.id === 'string' && selectedData.id.includes('_') ? selectedData.id.split('_')[1] : selectedData.id;
        if (dataFile == 'open') {
            setOpenText('Opening...')
        } else {
            setDownloadText('Downloading...')
        }
        ApiService.get('fileManager', {}, paramsid).then(function (res) {
            setDownloadText('Download')
            setOpenText('Open')
            let currentFileType = res.headers['content-type'];
            let link = document.createElement('a');
            link.download = selectedData.name;
            let blobtemp = new Blob([res.data], { type: currentFileType });
            link.href = URL.createObjectURL(blobtemp);
            if (dataFile == 'download' || canOpen.indexOf(currentFileType) == -1) {
                link.click();
                URL.revokeObjectURL(link.href);
            } else {
                let url = URL.createObjectURL(blobtemp);
                console.log({url})
                window.open(url, "_blank");
            }

            return res;
        })
        var formData = {
            activityName: dataFile == 'download' ? "Downloaded" : "Opened",
            mediaType: selectedData.type,
            mediaId: paramsid,
            // activityName: selectedData.name,
        }
        console.log({formData,selectedData});
        // return;
        ApiService.post('filemanagerActivity', formData).then(res => {
            let data = res.data.data
        })
    };

    return { downloadFile };
};

export default openDownload;