import React, { useState } from "react";
// import { registerUser } from '../../_services/user'
import { useNavigate, Link } from "react-router-dom";
import logo from "../Login/images/logo.png";
import { Box, Button, Container, CssBaseline, Dialog, DialogTitle, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
// import './register.css';
import { resetPasswordHandler } from "auth-component"
import { changePassword } from "../auth";


function ResetPassword(props) {

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNPassword, setShowNPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
    const [newPassErr, setNewPassErr] = useState(false);
    const [c_passErr, setC_PassErr] = useState(false);
    const [open, setOpen] = useState(false);
    const [dailogMessage, setDailogMessage] = useState('')

    const navigate = useNavigate();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var regexp = " /^[0-9\b]+$/";
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;


    const submitClickHandler = (e) => {
        e.preventDefault();

        let postData = {};

        if (mailformat.test(email)) {
            postData.email = email;
            setEmailErr(false)
        } else {
            setEmailErr(true)
        }
        if ((newPassword.match(regexp) || format.test(newPassword)) && (password.match(regexp) || format.test(password))) {
            postData.new_password = newPassword;
            setNewPassErr(false)
        } else {
            setNewPassErr(true)
        }
        if (confirmPassword == newPassword) {
            postData.c_password = confirmPassword;
            setC_PassErr(false)
        } else {

            setC_PassErr(true)
        }

        if (password.match(regexp) || format.test(password)) {
            postData.password = password;
            setPassErr(false)
        } else {
            setPassErr(true)
        }


        if (mailformat.test(email) && (confirmPassword == newPassword) && (newPassword.match(regexp) || format.test(newPassword)) && (password.match(regexp) || format.test(password))) {
            // props.setOpenBackdrop(true);
            changePassword(postData).then(r =>{
                // console.debug("changePassword", r)
                let response = r.data
                // console.debug("changePassword", response)
                if (response.errors) {
                    setDailogMessage(response.errors[0].detail)
                } else {
                    console.debug("changePassword", response.data[0])
                    setDailogMessage(response.data[0].detail)
                    setTimeout(function () {
                        console.debug("setDailogMessage",window.location)
                        // window.location.pathname("#/login")
                        navigate("/login");
                    }, 3000)
                }
                handleDialogStatus()
            })
            // resetPasswordHandler(postData).then(r=>{
            //     console.debug("ssoLinkingNewHandler:r", r)
            //     // if(r !=null){ 
            //     //     if(r){
            //     //         handleDialogStatus()
            //     //         setDailogMessage(r.detail)
            //     //     }
            //     // }else{

            //     // }
            // })
        }

    }

    const handleDialogStatus = () => {
        setOpen(!open);
    };

    const formInputChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == 'email') {
            if(mailformat.test(e.target.value)){ 
            setEmail(e.target.value)
            setEmailErr(false);
            }else{
                setEmail(e.target.value)
                setEmailErr(true);
            }
        }
        if (e.target.name == 'password') {
            if (password.match(regexp) || format.test(password)){ 
            setPassword(e.target.value)
            setC_PassErr(false);
            }else{
                setPassword(e.target.value)
                setC_PassErr(true);
            }
        }
        if (e.target.name == 'newPassword') {
            if (newPassword.match(regexp) || format.test(newPassword)){ 
            setNewPassword(e.target.value)
            setNewPassErr(false)
            }else{
                setNewPassword(e.target.value)
                setNewPassErr(true)  
            }
        }
        if (e.target.name == 'confirmPassword') {
            if (e.target.value.match(newPassword)){
            setConfirmPassword(e.target.value)
            setC_PassErr(false)
            }else{
                setConfirmPassword(e.target.value)
                setC_PassErr(true)
            }
        }
    }



    return (
        <Grid container component="main" sx={{ height: '100vh', background: '#E9EAED' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    mt: 10,
                    alignItems: 'center',
                }}
            >     <Box className="align-content">

                    <a href="#/login"><img width={150} height={130} src={logo} alt="logo" /></a>

                </Box>
                <Box className="align-content">
                    <Box ><Typography variant="h5" component="div" >Every interaction is an opportunity</Typography>   </Box>
                    <Box sx={{
                        mt: 0
                    }}><Typography variant="h5" component="div" >to create <strong>something remarkable.</strong></Typography> </Box>
                    <hr className="custom-register-line" />
                    <Box className="align-content">
                        <i className="">The Tingg Motto</i>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end" }} style={{ marginRight: '2rem' }}>
                    <Box sx={{ mt: 3, alignItems: 'end' }}> Got an account? <a href="#/login" style={{ color: '#007bff' }}>Sign in.</a></Box></Box>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Change Your Password
                    </Typography>
                    <Box sx={{ mt: 0 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={(e) => formInputChangeHandler(e)}
                            error={emailErr} 
                            helperText={emailErr ? "Please enter a valid email account":""}
                            // onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                        />
                        {/* {emailErr} */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Old Password"
                            type={showPassword ? "text" : "password"}
                            // type="password"
                            id="password"
                            onChange={(e) => formInputChangeHandler(e)}
                            error={passErr} 
                            helperText={passErr ? "Please enter your old password":""}
                            // onChange={(e) => setPassword(e.target.value)}
                            autoComplete="password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* {passErr} */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label="New Password"
                            type={showNPassword ? "text" : "password"}
                            // type="password"
                            id="newPassword"
                            // onChange={(e) => setNewPassword(e.target.value)}
                            onChange={(e) => formInputChangeHandler(e)}
                            error={newPassErr} 
                            helperText={newPassErr ? "Please enter valid password First letter should be capital and contain special character and number":""}
                            autoComplete="newPassword"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowNPassword(!showNPassword)}>{showNPassword ? 'Hide' : 'Show'}</Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                       
                           <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type={showCPassword ? "text" : "password"}
                            // type="password"
                            id="confirmPassword"
                            // onChange={(e) => setConfirmPassword(e.target.value)}
                            onChange={(e) => formInputChangeHandler(e)}
                            error={c_passErr} 
                            helperText={c_passErr ? " Please enter valid confirm password":""}
                            autoComplete="confirmPassword"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowCPassword(!showCPassword)}>{showCPassword ? 'Hide' : 'Show'}</Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* {c_passErr} */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={submitClickHandler}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }} ><p>By creating an account, I accept Tingg’s
                    <br />

                    <Link style={{ color: '#007bff' }} to="https://tinggit.com/terms-of-service" target="_blank"><u>Terms of Service</u></Link> and <Link style={{ color: '#007bff' }} to="https://tinggit.com/privacy-policies" target="_blank"><u>Privacy Policy</u></Link></p>
                </Box>
                <Dialog onClose={handleDialogStatus} aria-labelledby="simple-dialog-title" open={open}>
                    <DialogTitle id="simple-dialog-title">{dailogMessage}</DialogTitle>
                </Dialog>
            </Grid>
        </Grid>


    )
}

export default ResetPassword;