
const longPressHelper = () => {

    let self = {};
    self.pressTimer = () => {};
    self.callback = () => {};
    self.callbackTouchEnd = () => {};

    self.touchstart = (event,id) => {
        console.log("touchstart");

        self.pressTimer = setTimeout(function() {

            console.log('Long press detected');
            self.callback(id);

            // Add your code to handle long press here

        }, 1000); // Adjust the time (in milliseconds) for what you consider a long press
    }

    self.touchmove = (event) => {
        // If the touch moves, clear the timer
        console.log("touchmove");
        clearTimeout(self.pressTimer);
    }

    self.touchend = (id) => {
        console.log("touchend");
        // Clear the timer on touch end

        clearTimeout(self.pressTimer);
        self.callbackTouchEnd(id)
    }

    return self;
}

export default longPressHelper;