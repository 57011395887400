import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MyContext from './Mycontext';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Can from './Permission/Can';
import { userPermissions } from './Permission/required.permission';
import { useState } from 'react';
import openDownload from './openDownload';
import InfoIcon from '@mui/icons-material/Info';
import PushPinIcon from '@mui/icons-material/PushPin';
import unpin from '../../assets/images/KM-Manager/unpin.svg';
import PinUnpin from './PinUnpin';
import { IconButton } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckPermission from './Permission/CheckPermission';

function MenuBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const methodsAndState = React.useContext(MyContext);
  const userPermissionsListRedux = useSelector((state) => (state.permission));
  const [userPermissionsList, setUserPermissionsList] = React.useState([]);
  const [matchingSelectedData, setMatchingSelectedData] = useState([]);
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [openText, setOpenText] = useState('Open');
  const [downaloadText, setDownloadText] = useState('Download');
  const pinunpinshow = matchingSelectedData.every(item => item.pinned === true) || window.location.hash == "#/pinned"
  const [pinValue, setPinValue] = useState(window.location.hash == "#/pinned" ? "Unpin" : "Pin");
  const [disablePinBtn, setDisablePinBtn] = useState(false);
  const matches = useMediaQuery('(min-width:1024px)');
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // checkboxGridHelper.clearSelection();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { downloadFile } = openDownload();
  const { pinUnpinHandler } = PinUnpin();

  const onHandlerDownloadOpen = (data) => {
    let matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
    if( props.isRecentFiles == true){
      matchingData = matchingData.map(v => {return {...v,id: v.mediaId}});
    }
    console.log({matchingData})
    downloadFile(data, matchingData[0], setOpenText, setDownloadText);
    // downloadFile(data, methodsAndState.selectedId, setOpenText, setDownloadText);
  };

  // React.useEffect(() => {
  //   if (userPermissionsListRedux.permissionList) {
  //     setUserPermissionsList(userPermissionsListRedux.permissionList)
  //   }
  // }, [userPermissionsListRedux])

  React.useEffect(() => {
    if (userPermissionsListRedux.permissionList) {
      setUserPermissionsList(userPermissionsListRedux.permissionList);
      setShowThreeDots(showMenu() != 0);
    }
  }, [userPermissionsListRedux])

  const showMenu = () => {
    let count = 0;
    if(window.location.pathname == "/trash" || window.location.pathname == "#/trash"){
      if(CheckPermission(userPermissionsList,[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]))
        count++;
      return count;
    }
    if(CheckPermission(userPermissionsList,[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN])){
      count += ((methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory')) ? 0 : matches ? 0 : process.env.REACT_APP_ENV === 'teamDev' || process.env.REACT_APP_ENV === 'teamProd' ? 0 : 1);
      // console.log(((methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory')) ? 0 : matches ? 0 : process.env.REACT_APP_ENV === 'teamDev' || process.env.REACT_APP_ENV === 'teamProd' ? 0 : 1));
    }
    if(CheckPermission(userPermissionsList,[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_EDIT])){
      count += (methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? 0 : matches ? 0 : 1)
      // console.log((methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? 0 : matches ? 0 : 1))
    }
    if(CheckPermission(userPermissionsList,[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_RENAME || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_RENAME])){
      count += (methodsAndState.selectedItems.size > 1 ? 0 : matches ? 0 : 1)
      // console.log((methodsAndState.selectedItems.size > 1 ? 0 : matches ? 0 : 1))
    }
    if(CheckPermission(userPermissionsList,[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_MOVE])){
      count += (methodsAndState.selectedItems.size > 1 || (window.location.pathname == "/recentfile" || window.location.pathname == "#/recentfile" )|| (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? 0
      : 1)
      // console.log((methodsAndState.selectedItems.size > 1 || (window.location.pathname == "/recentfile" || window.location.pathname == "#/recentfile" )|| (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? 0 : 1))
    }
    if(CheckPermission(userPermissionsList,[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE])){
      count += ((window.location.pathname == "/recentfile" || window.location.pathname == "#/recentfile") ? 0 : matches ? 0 : 1)
      // console.log(((window.location.pathname == "/recentfile" || window.location.pathname == "#/recentfile") ? 0 : matches ? 0 : 1))
    }
    if(!props.value && (pinunpinshow || matchingSelectedData.every(item => item.pinned === false)))
      count++;
    // console.log({count})
    return count;
  }

  React.useEffect(() => {
    if (methodsAndState.selectedItems.size && methodsAndState.dashboardList) {
      const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
      setMatchingSelectedData(matchingData)
      if (matchingData.every(item => item.pinned === true) || window.location.hash == "#/pinned") {
        setPinValue('Unpin')
      } else {
        setPinValue('Pin')
      }
    }

  }, [methodsAndState.selectedItems])

  const onHandlerPinUnpin = (d) => {
    setDisablePinBtn(true);
    pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, d, methodsAndState.setSelectedItems, methodsAndState.setSnackBar, methodsAndState.setDashboardList,setDisablePinBtn);
  }

  const buttonSX = {
    '&': { color: '#00000087' },
    '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
  }

  return (
    <Can
    userPermissions={userPermissionsList}
    requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
    yes={<Box> {showThreeDots != 0 &&
      <IconButton
        sx={buttonSX}
        id="demo-customized-button"
        aria-controls={openMenu ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>}
      {window.location.hash == "#/trash" ?
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          sx={{ color: 'red' }}
        >
          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
            yes={<MenuItem onClick={handleClose}>
            <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.restoreSelectedItems(props.data)} startIcon={<RestoreIcon />}>
              Restore
            </Button>
          </MenuItem>}
          />
          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
            yes={<MenuItem onClick={handleClose}>
            <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDialogPublish('deletePermanentDialog')} startIcon={<DeleteIcon />}>
              Permanent Delete
            </Button></MenuItem>}
          />
          
          

        </Menu>
        : <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          sx={{ color: 'red' }}
        >
          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN]}
            yes={methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
              :
              matches ? '' :
              <MenuItem onClick={handleClose}>
                <Button sx={buttonSX} variant="text" onClick={() => onHandlerDownloadOpen('open')} startIcon={<FileOpenIcon />}>
                  {openText}
                </Button>
              </MenuItem>}
          />
          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_EDIT]}
            yes=
            {methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
              :
              matches ? '' :
              <MenuItem onClick={handleClose}>
                <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDrawerPublish('editFile')} startIcon={<InfoIcon />}>
                  Details
                </Button>
              </MenuItem>}
          />
          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN]}
            yes={window.location.hash == "#/trash" || methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ?
              ""
              :
              <MenuItem onClick={handleClose}>
                <Button sx={buttonSX} variant="text" onClick={() => onHandlerDownloadOpen('download')} startIcon={<DownloadIcon />}>
                  {downaloadText}
                </Button>
              </MenuItem>}
          />

          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_RENAME || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_RENAME]}
            yes={methodsAndState.selectedItems.size > 1 ? null : 
            matches ? '' :
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDialogPublish('renameDialog')} startIcon={<DriveFileRenameOutlineIcon />}>
                Rename
              </Button></MenuItem>}
          />

          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_MOVE]}

            yes={methodsAndState.selectedItems.size > 1 || window.location.hash == "#/recentfile"|| (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
              :
              <MenuItem onClick={handleClose}>

                <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDialogPublish('moveDialog')}  startIcon={<KeyboardTabIcon />}>
                  Move to
                </Button>
                
              </MenuItem>}
          />

          <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
            yes={window.location.hash == "#/recentfile" ? "" :
              matches ? '' :
              <MenuItem onClick={handleClose}>
                <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDialogPublish('deleteDialog')} startIcon={<DeleteIcon />}>
                  Delete
                </Button></MenuItem>}
          />
          {/* {pinunpinshow || matchingSelectedData.every(item => item.pinned === false) ? */}
          {!props.value ? pinunpinshow || matchingSelectedData.every(item => item.pinned === false) ?
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => onHandlerPinUnpin(pinValue)} startIcon={pinunpinshow ? <img src={unpin} /> : <PushPinIcon />}>
                {pinValue}
              </Button>
            </MenuItem> : null : null}
        </Menu>}
    </Box>} />
  );
}
export default MenuBar;