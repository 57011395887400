
import { createSlice } from '@reduxjs/toolkit';
const categoryData = createSlice({
    name: 'category',
    initialState: {
        categoryList: [],
        // filterCategory: []
    },
    reducers: {

        setCategoryDataList: (state, action) => {
            state.categoryList = action.payload;
        },
        // setFilterCategory: (state, action) => {
        // state.filterCategory = action.payload;
        // },
    },
});

export const { setCategoryDataList } = categoryData.actions;
export default categoryData.reducer;
