import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Box, Grid, Stack, Typography, IconButton, TextField, Autocomplete, Chip, Link, Avatar, Skeleton, CircularProgress, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../../features/drawers/drawerSlice';
import WordFile from "../../../assets/images/word.png";
import PDFFile from "../../../assets/images/pdf.png";
import ExcelFile from "../../../assets/images/excel.png"
import ImageFile from "../../../assets/images/picUpload.png"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LinearProgress from '@mui/material/LinearProgress';
import UploadService from "../../../Service/fileUploadService";
import ApiService from '../../../utils/ApiService';
import MyContext from '../Mycontext';
import { getAppToken } from "../../../_helper/secureToken";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    // padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 2,
  },
}));

export const FileDashboardKM = (parentId) => {
  const methodsAndState = useContext(MyContext);
  let { id } = useParams();

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  //Upload area//
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [message, setMessage] = useState([]);
  const [fileDescriptions, setFileDescriptions] = useState('');
  const [selectedTagArray, setSelectedTagArray] = useState([]);
  const [fileUploadData, setfileUploadData] = useState();
  const [categoriesName, setCategoriesName] = useState([]);
  const progressInfosRef = useRef(null);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [allTags, setAlltags] = useState([])
  const [fileName, setFilename] = useState("")
  const [flag, setFlag] = useState(false)
  const [extensionErr, setRxtensionErr] = useState(false)
  const [tagCharacterLimitErr, setTagCharacterLimitErr] = useState(false)
  // const [selectedCategories, setSelectedCategoriesName] = useState([])
  const categoryListData = useSelector((state) => (state.category));
  const userList = useSelector((state) => state?.user?.userListName);
  // const isMobileWithGoButton = /Go|Next/.test(navigator.userAgent);
  // const tagsField = useRef();

  let obj = [0, 1, 2, 3, 4];
  const allowedExtensions = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.ppt', '.pptx', '.xlsx', '.xlsm', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.doc', '.docx'];
  const [validCategory, setValidCategory] = useState([])
  const selectFiles = (event) => {

    const file = event.target.files[0];

    if (file) {
      const invalidFiles = [];
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      const fileExtension = '.' + file.name.split('.').pop();
      if (allowedExtensions.includes(fileExtension.toLowerCase()) && fileSizeInMB <= 25) {
        const imageUrl = URL.createObjectURL(file);
        setSelectedFiles([file]);
        setImagePreviews([imageUrl]);
        setProgressInfos({ val: [] });
        setMessage([]);
        setInputKey(inputKey + 1);
        setfileUploadData([file]);
        const progressInfo = {
          percentage: 0,
          fileName: file.name,
          fileSize: file.size
        };
        progressInfosRef.current = {
          val: [progressInfo],
        };
        upload(0, file);
        setRxtensionErr(false)
      } else {
        event.target.value = null; // Clear the input
        setRxtensionErr(fileSizeInMB <= 25 ? 'File(s) not supported. Upload a supported format.' : 'File size exceeds the limit (25MB).')
      }
    }
  };

  const handleRemove = (index) => {
    let removeFiles = [...selectedFiles];
    removeFiles.splice(index, 1);
    // setState({ uploadedFiles });
    setSelectedFiles([...removeFiles]);

    let removeImagePreviews = [...imagePreviews];
    removeImagePreviews.splice(index, 1);
    setImagePreviews(removeImagePreviews);

    let removeProgressInfos = [...progressInfos.val];
    removeProgressInfos.splice(index, 1);
    setProgressInfos({ val: removeProgressInfos });
  }

  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];

    setProgressInfos({ val: _progressInfos });

  };

  // useEffect(() => {
  //   if (!tagsField.current) return;

  //   console.log({current: tagsField.current})
  //   // Check if enterkeyhint is set
  //   const currentEnterkeyhint = tagsField.current.enterkeyhint;

  //   if (!currentEnterkeyhint || currentEnterkeyhint != 'enter') {
  //     // Set the enterkeyhint to "enter" if missing
  //     tagsField.current.enterkeyhint = 'enter';
  //   }
  // }, [tagsField.current]);

  const inputElementsRef = useRef([]);
  useEffect(() => {
    const inputElement = document.getElementById('tags-outlined');
    inputElement.setAttribute("enterkeyhint", "enter");
    // Update the useRef array with the new elements
    // inputElementsRef.current = inputElements;
    // inputElementsRef.enterKeyHint = 'enter';
    console.log({inputElement});

    // Set the enterKeyHint property for all elements
    // for (const element of inputElements) {
    //   element.enterKeyHint = 'enter';
    // }
  }, [])

  const onChangeOptionHandler = (e, value) => {
    const characterLimit = 80;
    // Check for tags that exceed the character limit
    const exceedingTags = value.filter((tag) => tag.length > characterLimit);
    if (exceedingTags.length > 0) {
      setTagCharacterLimitErr(true);
    } else {
      setTagCharacterLimitErr(false)
    }
    setSelectedTagArray(value)
  }

  const onChangeSelectCategory = (e, value, reason, data, i) => {
    // If the reason for the change is 'removeOption', handle the removal.
    if (reason === "removeOption" && value) {
      let newCategoryName = categoriesName
      const prevValue = newCategoryName.filter(v => v.category[0].id == data.id)
      const currValue = value.map(v => v.id);
      const lostValue = prevValue.filter(v => currValue.indexOf(v.id) == -1)[0].id;
      // Filter out the selected value from categoriesName.
      const index = newCategoryName.reduce((prev, curr, index) => {
        if (curr.id == lostValue)
          prev = index;
        return prev;
      }, -1);
      // Remove the selected value from the categoriesName array.
      if (index != -1)
        newCategoryName.splice(index, 1);
      setCategoriesName([...newCategoryName]); // Creating a new array reference to trigger state update
    } else if (reason === "clear") {
      let newCategoryName = categoriesName
      const currValue = newCategoryName.filter(v => v.category[0].id != data.id)
      setCategoriesName([...currValue]);
    } else {
      // Handle the addition of new values (similar to your existing code).
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value.length) {
        const uniqueValues = new Set([...categoriesName, ...value]);
        setCategoriesName(Array.from(uniqueValues));
      }
    }
    let validation = [...validCategory, { index: i, value: value }]
    const uniqueValuesMap = new Map();
    const updatedData = [];

    validation.forEach(item => {
      const index = item.index;
      if (!uniqueValuesMap.has(index)) {
        uniqueValuesMap.set(index, updatedData.length);
        updatedData.push(item);
      } else {
        const existingIndex = uniqueValuesMap.get(index);
        const existingValues = updatedData[existingIndex].value.map(val => val.id);

        item.value.forEach(val => {
          if (!existingValues.includes(val.id)) {
            updatedData[existingIndex].value.push(val);
            existingValues.push(val.id);
          }
        });
      }
    });

    // Update the validCategory array with the filtered data
    setValidCategory(updatedData);

  }

  const isOptionEqualToValue = (value, option) => {
    return value.subCategoryName === option.subCategoryName;
  };

  useEffect(() => {
    ApiService.get('tags').then(res => {
      let data = res.data.data
      if (data) {
        setAlltags(data)
      }
    }).catch((error) => {
      console.log(error);
    })
    if (methodsAndState.selectedItems.size == 1 && methodsAndState.editFlag.value != "create") {
      const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
      let paramsid = typeof selectedData.id === 'string' && selectedData.id.includes('_') ? selectedData.id.split('_')[1] : selectedData.id;
      if(methodsAndState?.isRecentFiles){
        paramsid = selectedData.mediaId;
      }
      ApiService.get('fileDetail', {}, paramsid).then(res => {
        if (res.data.data) {
          let data = res.data.data;
          setFileDescriptions(data.description != null ? data.description : "");
          setFilename(data.name);
          setCategoriesName(data.subCategory)
          setSelectedTagArray(data.tags.map((temp) => {
            return temp.name.en;
          }));
        }
      })
    }
  }, [])


  const handleFilenameChange = (e) => {
    if (e.target.name == "filename") {
      setFilename(e.target.value);
    }
    if (e.target.name == "filedescription") {
      setFileDescriptions(e.target.value);
    }
  };

  const editHandler = () => {
    methodsAndState.setEditFlag({ flag: true, value: "edit" })
  }

  const createFile = () => {

    let formData = new FormData();
    formData.append('app', getAppToken());
    // if (fileName) {
    //   formData.append('fileName', fileName);
    // }
    if (fileUploadData) {
      formData.append('file', fileUploadData[0]);
    }
    if (fileDescriptions) {
      formData.append('fileDescription', fileDescriptions);
    }
    if (selectedTagArray.length) {
      selectedTagArray.forEach((item) => {
        formData.append('filetag[]', item)
      });
    }
    // if (methodsAndState.selectedItems.size == 1 && id == undefined) {
    //   var params = (Array.from(methodsAndState.selectedItems)[0]).split("_")[1]
    // //   console.debug("filedashboard: params if", { params, id })
    // } 
    // else {
    // const paramsid = typeof methodsAndState.selectedId.id === 'string' && methodsAndState.selectedId.id.includes('_') ? methodsAndState.selectedId.id.split('_')[1] : methodsAndState.selectedId.id;
    // const paramsid = typeof id === 'string' && id.includes('_') ? id.split('_')[1] : id
    // const paramsid = id == undefined ? "0" : id
    // formData.append('parentFolder', paramsid == undefined ? "0" : paramsid);
    // console.debug("filedashboard: paramsid else", { paramsid, id, editFlag: methodsAndState.editFlag.value })
    // }
    // console.debug("filedashboard: paramsid else", { paramsid, id, params, editFlag: methodsAndState.editFlag.value })
    if (methodsAndState.editFlag.value == "create") {
      const paramsid = id == undefined ? '0' : id
      formData.append('parentFolder', paramsid);
    } else {
      console.log(methodsAndState.selectedItems,Array.from(methodsAndState.selectedItems),(Array.from(methodsAndState.selectedItems)?.[0]))
      var params = (Array.from(methodsAndState.selectedItems)?.[0]);
      if(methodsAndState?.isRecentFiles){
        const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
        params = selectedData.mediaId;
      } else {
        params = (typeof params == 'string') ? params.split("_")?.[1] : params;
      }
    }
    const uniqueArray = Array.from(new Set(categoriesName.map(JSON.stringify))).map(JSON.parse);

    if (uniqueArray.length) {
      uniqueArray.forEach(d => {
        formData.append('subCategoryId[]', d.id)
      })
    }

    formData.append('userId', userList?.userId);

    if (selectedTagArray.length) {
      setFlag(true)
      let fileDashboard = methodsAndState.dashboardList.filter(v => methodsAndState.selectedItems.has(v.id))
      ApiService.post('fileManager', formData, params).then(res => {
        let data = res.data.data ? res.data.data : res.data
        console.log({data,res})
        setFlag(false)
        if(data?.status == '400'){
          const message = data.title;
          methodsAndState.setSnackBar({ dataLoad: true, message: message, severity: "error" })
          return;
        }
        if (methodsAndState.editFlag.value == "create") {
          let newFolderData = methodsAndState.dashboardList.filter(v => {
            return true;
          })
          const newObject = {
            name: data.name,
            id: "file_" + data.id,
            parentFolder: methodsAndState.selectedId.name == "" ? "root" : methodsAndState.selectedId.name,
            parentFolderId: data.parentFolder,
            type: data.type == null ? fileDashboard[0].type : data.type,
            description: data.fileDescription
          };
          const newDataWithNewObject = [...methodsAndState.dashboardList, newObject];
          methodsAndState.setDashboardList(newDataWithNewObject);
          methodsAndState.updateMetaData( 1 );
        } else {
          let renameData = methodsAndState.dashboardList.filter(v => {
            if (methodsAndState.selectedItems.has(v.id)) {
              v.name = data.name
            }
            return v;
          })
          methodsAndState.setDashboardList(renameData)

        }
        const message = methodsAndState.editFlag.value == "create" ? "New file has been successfully uploaded." : "File has been successfully updated."
        methodsAndState.setSnackBar({ dataLoad: true, message: message, severity: "info" })
        methodsAndState.setSelectedItems(new Set([]))
        handleDrawerClose()
      }).catch((error) => {
        methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        setFlag(false)
      })
    }

  }

  const filteredValidData = validCategory.map(item => {
    item.value = item.value.filter(subItem => {
      return categoriesName.some(categoryItem => categoryItem.subCategoryName === subItem.subCategoryName);
    });
    return item;
  });

  // Check if every filteredValidData has a 'value' property with a length greater than 0
  const hasValueLength = filteredValidData.every(item => item.value.length > 0);

  const isSubmitDisabled = methodsAndState.editFlag.value == "edit" ? (hasValueLength && !tagCharacterLimitErr) ? false : true
    :
    !(fileUploadData && selectedTagArray.length > 0 && validCategory.length == categoryListData.categoryList.length && hasValueLength && !tagCharacterLimitErr) || selectedFiles.length === 0;
  const [inputKey, setInputKey] = useState(0);
  const disabledFields = methodsAndState.editFlag.flag || methodsAndState.editFlag.value == "create";

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
  
  
    
    const files = event.dataTransfer.files;
  
    // Validate files and call selectFiles function
    selectFiles({...event, target: {...event.target,files: files}});
  };

  return (
    <Box sx={{ width: { sm: '40rem' } }}>
      <Stack sx={{ backgroundColor: "var(--color-lightblue)" }}>
        <Box sx={{ p: '.5rem .1rem' }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={10}>
              <Box pl='1rem' display='flex'>
                <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >{methodsAndState.editFlag.value == "create" ? "Upload File" : methodsAndState.editFlag.flag ? "Edit File" : "View File"}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                <CloseIcon sx={{ color: 'var(--color-black)' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Box style={{ padding: "15px", overflow: 'auto', pt: 'var(--equal-hw1)', height: '80vh' }}>
        {methodsAndState.selectedItems.size && methodsAndState.editFlag.value != "create" ?
          <Box sx={{ mb: 2 }}>
            <TextField fullWidth
              value={fileName}
              onChange={handleFilenameChange}
              id="outlined-basic"
              label="File Name"
              name="filename"
              variant="outlined"
              disabled={true}
            />
          </Box>
          :
          <Box sx={{ mb: 2 }}>
            <Box>
              <Box>
                <label htmlFor="fileUploadKM">
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: "0.5rem",
                      border: "2px dashed rgba(0, 0, 0, 0.12)",
                      borderRadius: "12px",
                      backgroundColor: "rgba(201, 201, 201, 0.1)",
                      ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                      <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                        <UploadFileIcon color="primary" />
                      </Avatar>
                    </Box>
                    <Box className="upload-btn-wrapper">
                      <span>
                        <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                          Click to upload
                        </Link>
                        <input
                          id="fileUploadKM"
                          key={inputKey}
                          type="file"
                          name="myfile"
                          multiple
                          accept=".png, .jpg, .jpeg, .xls, .pdf, .ppt, .pptx, .xlsx, .xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .doc, .docx, application/msword"
                          // accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={selectFiles}
                        />

                      </span>
                      <span> or drag and drop *</span>
                    </Box>
                    <Box m='1rem 0rem 0rem'>
                      <Typography variant="caption" color="text.secondary" display="block" gutterBottom> PNG, JPG, XLS,PDF and PPT (max. 25mb)</Typography>
                    </Box>
                    <Box mb='1rem'>
                      {extensionErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                        {extensionErr}
                      </Alert> : ""}
                    </Box>
                  </Stack>
                </label>
              </Box>
            </Box>
            <Box sx={{ ml: '3rem' }}>
              {progressInfos &&
                progressInfos.val.length > 0 &&
                progressInfos.val.map((progressInfo, index) => (
                  <Box key={index} sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2} md={2}>
                        <Box>
                          {(selectedFiles?.[index]?.name.endsWith(".docx")) ?
                            (
                              <>
                                <img
                                  className="preview"
                                  src={WordFile}
                                  alt={"image-" + index}
                                  key={index}
                                />
                              </>
                            )
                            : selectedFiles?.[index]?.name.endsWith(".pdf") ? (
                              <img
                                className="preview"
                                src={PDFFile}
                                alt={"image-" + index}
                                key={index}
                              />
                            ) : selectedFiles?.[index]?.name.endsWith(".xlsx") ||
                              selectedFiles?.[index]?.name.endsWith(".xls") ||
                              selectedFiles?.[index]?.name.endsWith(".csv") ? (
                              <img
                                className="preview"
                                src={ExcelFile}
                                alt={"image-" + index}
                                key={index}
                              />
                            ) : (
                              <img height={30} width={30} className="preview" src={ImageFile} alt={"image-" + index} key={index} />
                            )
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <Box>
                          <Box>
                            <HtmlTooltip title={progressInfo.fileName}>
                              <span>
                                {progressInfo.fileName.length > 28
                                  ? progressInfo.fileName.substring(0, 28) + '...'
                                  : progressInfo.fileName}
                              </span>
                            </HtmlTooltip>
                          </Box>
                          <Box sx={{ my: 1 }}>
                            <Stack direction="row" spacing={2}>
                              <span><li>{formatBytes(progressInfo.fileSize)}</li></span>

                              <span>
                                {message.length > 0 && (
                                  <Box className="alert alert-secondary" role="alert">
                                    <li>
                                      {message[index]}
                                    </li>
                                  </Box>
                                )}
                              </span>
                            </Stack>
                          </Box>
                          <Box>
                            {/* <LinearProgress value={progressInfo.percentage} /> */}
                            <LinearProgress variant="determinate" value={100} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'red' } }} />
                            {/* <LinearProgress variant="determinate" value={100} /> */}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <Box sx={{ mb: 1 }}>
                          <IconButton aria-label="upload picture" component="label" onClick={() => handleRemove(index)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </Box>
            <Box>
            </Box>
          </Box>}
        <Box sx={{ mb: 2 }}>

          <Autocomplete
            // sx={{ width: '38rem' }}
            multiple
            id="tags-outlined"
            value={selectedTagArray}
            options={allTags.map((option) => {
              if (option.name) {
                if (option.name.en) {
                  return option.name.en;
                }
              }
              return ""
            })}
            // ref={tagsField}
            freeSolo
            onChange={onChangeOptionHandler}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                id="enterTags"
                // enterKeyHint="enter"
                // onKeyDown={(event) => {
                //   console.log({isMobileWithGoButton,  keyCode: event.keyCode})
                //   if (isMobileWithGoButton && event.keyCode === 13) {
                //     // goButton.addEventListener('click', () => {
                //       // Trigger enter event on input element
                //       const enterEvent = new KeyboardEvent('keydown', { keyCode: 13 });
                //       (document.getElementById('enterTags')).dispatchEvent(enterEvent);
                //     // });
                //   }
                // }}
                {...params}
                label="Tags *"
                placeholder="Tags"
              />
            )}
            disabled={disabledFields ? false : true}
          />
          <Typography component="span" sx={{ fontSize: "12px", color: "#0072E5" }}>*Please press enter for creating a tag</Typography>
          {tagCharacterLimitErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
            `Tags must not exceed 80 characters.`
          </Alert> : ""}
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField fullWidth
            value={fileDescriptions}
            onChange={handleFilenameChange}
            label="File Description"
            placeholder="Description Here"
            multiline
            rows={2}
            name="filedescription"
            id="fileDescription"
            sx={{
              '& .MuiInputLabel-formControl:not(.Mui-focused)': {
                marginTop: '10px'
              },
            }}
            disabled={disabledFields ? false : true}
          />
        </Box>
        {categoryListData.categoryList.length ? categoryListData.categoryList.map((d, i) => (
          // {methodsAndState.categoryList.length ? methodsAndState.categoryList.map((d, i) => (
          <Box key={i} sx={{ mb: 2 }}>
            <Autocomplete
              // open={true}
              // sx={{ width: '38rem' }}
              key={i}
              multiple={d.selectType === 1}
              limitTags={2}
              onChange={(e, value, reason) => onChangeSelectCategory(e, value, reason, d, i)}
              value={categoriesName.filter(
                (item) => {
                  if (item.category[0].id == d.id) {
                    return d.subCategory;
                  }
                }
              )}
              options={d.subCategory}
              getOptionLabel={(option) => option.subCategoryName}
              renderInput={(params, index) => (
                <TextField {...params} label={d.categoryName + " *"} placeholder={d.categoryName + " *"} />
              )}
              disabled={disabledFields ? false : true}
              isOptionEqualToValue={isOptionEqualToValue}
            />
          </Box>
        )) :
          <Box >
            {obj.map((d, key) => {
              return (
                <Box key={key} sx={{ m: '2rem' }}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Box>
              );
            })}
          </Box>
        }
      </Box>
      <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem', zIndex: '1000' }}>
        <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
          {methodsAndState.selectedItems.size && !methodsAndState.editFlag.flag && methodsAndState.editFlag.value == "edit" ?
            <Button variant="contained" size="small" onClick={editHandler} startIcon={<EditIcon />} >EDIT DETAILS</Button> :

            flag == false ? <Button variant="contained" size="small" onClick={createFile} disabled={isSubmitDisabled} >SUBMIT</Button>
              :
              <Button variant="contained">
                <CircularProgress color={'success'} size="20px" />
              </Button>
          }
          <Button variant="text" size="small" onClick={handleDrawerClose} >CANCEL</Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default FileDashboardKM