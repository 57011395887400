import { Box, Typography, Grid, Checkbox, FormGroup, FormControlLabel, Button, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext, useEffect, useState } from 'react';
import { userPermissions } from './Permission/required.permission';
import MyContext from './Mycontext';
import Can from './Permission/Can';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { useSelector } from 'react-redux';
import openDownload from './openDownload';
import { useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import MenuBar from './menuBar';
import checkboxHelper from './checkboxHelper';

const DashboardKmTools = (props) => {
  const methodsAndState = useContext(MyContext);
  const userPermissionsListRedux = useSelector((state) => (state.permission));
  const [userPermissionsList, setUserPermissionsList] = useState([]);
  const [matchingSelectedData, setMatchingSelectedData] = useState([]);
  const [openText, setOpenText] = useState('Open');
  const [downaloadText, setDownloadText] = useState('Download');
  const [pinValue, setPinValue] = useState(window.location.hash == "#/pinned" ? "Unpin" : "Pin");
  const matches = useMediaQuery('(min-width:1024px)');
  const pinunpinshow = matchingSelectedData.every(item => item.pinned === true)
  useEffect(() => {
    if (userPermissionsListRedux.permissionList) {
      setUserPermissionsList(userPermissionsListRedux.permissionList)
    }
  }, [userPermissionsListRedux])

  const { downloadFile } = openDownload();

  const onHandlerDownloadOpen = (data) => {
    let paramsid = methodsAndState.selectedId;
    if(methodsAndState?.isRecentFiles){
      console.log({dashboardList: methodsAndState.dashboardList,paramsid})
      const selectedData = methodsAndState.dashboardList.find((item) => item.id == paramsid.id);
      paramsid = {...selectedData,id: selectedData.mediaId};
    }
    downloadFile(data, paramsid, setOpenText, setDownloadText);
  };
  useEffect(() => {
    if (methodsAndState.selectedItems.size && methodsAndState.dashboardList) {
      const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
      setMatchingSelectedData(matchingData)
    }

  }, [methodsAndState.selectedItems])

  const buttonSX = {
    '&': { color: '#00000087' },
    '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
  }
  const itemSelected = methodsAndState.selectedItems.size

  return (
    <Can
    userPermissions={userPermissionsList}
    requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
    yes={ <Box>
      {methodsAndState.selectedItems.size ? (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: { xs: 'wrap', sm: 'wrap' } }}
        >
          {methodsAndState.showTreeView ?
            <Box>{itemSelected > 1 ? itemSelected + ' Items Selected' : itemSelected + ' Item Selected'}</Box>
            : <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener}
                    checked={methodsAndState.headerCheckStatus}
                  />
                }
                label={itemSelected > 1 ? itemSelected + ' Items Selected' : itemSelected + ' Item Selected'}
              />
            </FormGroup>}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {window.location.hash == "#/trash" ?
              <Box>
                {matches ? (<Can
                userPermissions={userPermissionsList}
                requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
                yes={<>
                  <Button sx={buttonSX} onClick={() => methodsAndState.restoreSelectedItems(methodsAndState.checkedData)} startIcon={<RestoreIcon />}>Restore</Button>
                  <Button sx={buttonSX} onClick={() => methodsAndState.openDialogPublish('deletePermanentDialog')} startIcon={<DeleteIcon />}>Permanent Delete</Button>
                </>} />
                ) : (
                
                <Can
                userPermissions={userPermissionsList}
                requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
                yes={
                  <>
                  <IconButton sx={buttonSX} onClick={() => methodsAndState.restoreSelectedItems(methodsAndState.checkedData)} aria-label="Restore">
                  <RestoreIcon />
                </IconButton>
                <IconButton sx={buttonSX} onClick={() => methodsAndState.openDialogPublish('deletePermanentDialog')} aria-label="Permanent Delete">
                  <DeleteIcon />
                </IconButton>
                </>
                }
              />
           )}
              </Box>
              :
              <Box sx={{ display: "flex", alignItems: "center", }}>
                <Can
                  userPermissions={userPermissionsList}
                  requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN]}
                  yes={methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
                    :
                  (process.env.REACT_APP_ENV === 'teamDev' || process.env.REACT_APP_ENV === 'teamProd')? null : matches ? <Button sx={buttonSX} onClick={() => onHandlerDownloadOpen('open')} startIcon={<FileOpenIcon />}>{openText}</Button> : <IconButton sx={buttonSX} aria-label="Open" onClick={() => onHandlerDownloadOpen('open')}>
                      <OpenInFullIcon />
                    </IconButton>
                  }
                />
                <Can
                  userPermissions={userPermissionsList}
                  requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_EDIT]}
                  yes=
                  {methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
                    :
                    matches ? <Button sx={buttonSX} onClick={() => methodsAndState.openDrawerPublish('editFile')} startIcon={<InfoIcon />}>Details</Button> : <IconButton sx={buttonSX} onClick={() => methodsAndState.openDrawerPublish('editFile')} aria-label="Detail">
                      <InfoIcon />
                    </IconButton>
                  }
                />

                <Can
                  userPermissions={userPermissionsList}
                  requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_RENAME || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_RENAME]}
                  yes={methodsAndState.selectedItems.size > 1 ? ""
                    :
                    matches ? <Button sx={buttonSX} onClick={() => methodsAndState.openDialogPublish('renameDialog')} startIcon={<DriveFileRenameOutlineIcon />}>Rename</Button> : <IconButton sx={buttonSX} aria-label="Rename" onClick={() => methodsAndState.openDialogPublish('renameDialog')}>
                      <DriveFileRenameOutlineIcon />
                    </IconButton>
                  }
                />
                <Can
                  userPermissions={userPermissionsList}
                  requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
                  yes={window.location.hash == "#/recentfile" ? "" :
                    matches ?
                     <Button sx={buttonSX} onClick={() => methodsAndState.openDialogPublish('deleteDialog')} startIcon={<DeleteIcon />}>Delete</Button> : <IconButton sx={buttonSX} aria-label="Delete" onClick={() => methodsAndState.openDialogPublish('deleteDialog')}>
                      <DeleteIcon />
                    </IconButton>
                  } />
            
                {/* -----------------------MENU 3 DOTS COMPONENT ------------ */}
                {/* {methodsAndState.selectedItems.size > 1 ? null : */}
                {matchingSelectedData.every(item => item.pinned === true) || matchingSelectedData.every(item => item.pinned === false) ?
                  <MenuBar data={methodsAndState.checkedData} value={false} isRecentFiles={methodsAndState.isRecentFiles} />
                  : null}
                {/* } */}
              </Box>
            }
          </Box>
        </Box>
      ) : null}
    </Box> } />
  );
};

export default DashboardKmTools;