import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Folder } from '@mui/icons-material';
import MyContext from './Mycontext';
function MenuBreadcrumb({ move, breadCrumb,openFolderBreadcrumb }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const methodsAndState = React.useContext(MyContext);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const buttonSX = {
        '&': { color: '#00000087' },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
    }

    return (
        <Box>
            <Button
                id="demo-customized-button"
                aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                sx={{ color: 'red' }}
            >
                {
                    move ? breadCrumb.slice(0, breadCrumb.length - 2).map(d => (
                        <MenuItem key={d.id}>
                            <Button sx={buttonSX} variant="text" onClick={() => openFolderBreadcrumb(d)} startIcon={<Folder />}>
                                {d.name}
                            </Button>
                        </MenuItem>
                    ))
                        :
                        methodsAndState.breadCrumb.slice(0, methodsAndState.breadCrumb.length - 2).map(d => (
                            <MenuItem key={d.id}>
                                <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openFolderBreadcrumb(d)} startIcon={<Folder />}>
                                    {d.name}
                                </Button>
                            </MenuItem>
                        ))
                }
            </Menu>
        </Box>
    );
}
export default MenuBreadcrumb;