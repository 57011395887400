import { useSelector } from "react-redux";
import { getAppToken } from "../../_helper/secureToken";
import ApiService from "../../utils/ApiService";
import { useState } from 'react';

const PinUnpin = () => {
    const userList = useSelector((state) => state?.user?.userListName);
    const isRecent = window.location.hash == "#/recentfile";

    const pinUnpinHandler = (dashboardList, selectedItems, pinValue, setSelectedItems, setSnackBar, setDashboardList, setDisablePinBtn) => {

        let successfullItems = new Set([]);

        let pinUnpinData = dashboardList.filter(v => {
            if (selectedItems.has(v.id)) {
                return v;
            }
        })
        let data = new FormData();
        data.append(`userId`, userList?.userId);
        data.append('app', getAppToken());
        valueChangeHandler()

        if (window.location.hash == "#/pinned" || pinValue == "Unpin") {
            let params = pinUnpinData.forEach((val, i) => {
                data.append(`unpins[${i}][type]`, val.type == "directory" ? 'folder' : 'file');
                data.append(`unpins[${i}][${val.type == "directory" ? 'folderId' : 'fileId'}]`, (isRecent) ? val.mediaId : (typeof val.id === 'string' && val?.id?.includes('_')) ? val.id.split('_')[1] : val.id);
            });
            pinnedUnpinnedHandler('unpinned', data)
        } else {
            let params = pinUnpinData.forEach((val, i) => {
                data.append(`pins[${i}][type]`, val.type == "directory" ? 'folder' : 'file');
                data.append(`pins[${i}][${val.type == "directory" ? 'folderId' : 'fileId'}]`, (isRecent) ? val.mediaId : (typeof val.id === 'string' && val?.id?.includes('_')) ? val.id.split('_')[1] : val.id);
            });
            pinnedUnpinnedHandler('filefolderPin', data)
        }

        function pinnedUnpinnedHandler(apiCall, data) {
            ApiService.post(apiCall, data).then(res => {
                setDisablePinBtn(false);
                // let data = res.data.data
                if(res.data?.success?.length || res.data?.error?.length){
                    let mode = typeof (pinUnpinData[0].id) == 'string' && pinUnpinData[0].id?.includes('_');
                    let success = res.data?.success?.map(v => {return (mode ? (v.pinned_id.type + "_") : "") + (v.pinned_id?.fileId ?? v.pinned_id?.folderId)}) ?? []
                    let error = new Set(res.data?.error?.map(v => {return (mode ? (v.pinned_id.type + "_") : "") + (v.pinned_id?.fileId ?? v.pinned_id?.folderId)}) ?? [])
                    successfullItems = new Set(success);
                    let errorData = dashboardList.filter(v => {
                        if (error.has(v.id) || (isRecent && pinValue == "Unpin" && selectedItems.has(v.id))) {
                            return v;
                        }
                    })
                    console.log({errorData})
                    if(errorData.length){
                        errorData.forEach((val,i) => {
                            setSnackBar({ dataLoad: true, message: val.name + " Failed to update, Pinned Limit Exceeded.", severity: "warning" })
                        })
                    }
                    console.log({res,success,error,selectedItems});
                }
                valueChangeHandler()
              
            }).catch((error) => {
                setDisablePinBtn(false);
                console.error(error);
                // setLoading(false)
                valueChangeHandler()
                let params = pinUnpinData.forEach((val, i) => {
                    setSnackBar({ dataLoad: true, message: val.name + " Failed to update", severity: "error" })
                });
                // setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
            })
        }

        function valueChangeHandler() {
            if (window.location.hash == "#/pinned") {
                let pinunpinData = dashboardList.filter(v => !selectedItems.has(v.id))
                setDashboardList(pinunpinData)
            }
            else {
                // console.log({dashboardList,selectedItems,successfullItems})
                let pinunpinData = dashboardList.filter(v => {
                    // return item.id !== v.id
                    if (pinValue == "Unpin" && selectedItems.has(v.id)) {
                        v.pinned = false;
                    } else if(pinValue != "Unpin" && successfullItems.has(v.id)){
                        v.pinned = true;
                    } else if(pinValue != "Unpin" && isRecent && successfullItems.has("" + v.mediaId)){
                        v.pinned = true;
                    }
                    // console.log(pinValue != "Unpin" && isRecent && successfullItems.has("" + v.mediaId));
                    return v;
                })
                console.log({pinUnpinData})
                setDashboardList(pinunpinData)
            }
            // setSnackBar({ dataLoad: true, message: `${pinValue == "Unpinned" ? "Successfully unpinned." : "Successfully pinned."}`, severity: "info" })
            setSelectedItems(new Set([]))
        }
    };

    return { pinUnpinHandler };
};

export default PinUnpin;