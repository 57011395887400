import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { closeDialog } from './dialogBoxSlice';

function SharedDialog({children,...props}) {
  const dispatch = useDispatch();
  // const id = 'moveDialog';
  const id = props?.id;
  // const isOpen =id == 'moveDialog'? true:false
  const isOpen = useSelector((state) => { return (state.dialog.id == id) ? state.dialog.isOpen : false});

  const disableBackdropClick = ['moveDialog'];

  const handleDialogClose = (event, reason) => {
    if(disableBackdropClick.indexOf(id) != -1 && reason == 'backdropClick'){
      return;
    }
    dispatch(closeDialog(id));
  };

  return (
    <Dialog
    open={isOpen}
    onClose={handleDialogClose}
    aria-labelledby="dialog-title"
    >
    {children}
    </Dialog>
  );
}

export default SharedDialog;
