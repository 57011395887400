import React from "react";
// import Home from "../pages/Home";
import DashboardKM from "../pages/KnowledgeManagement/Dashboard";
import Sidebar from "../layouts/sidebar/Sidebar";
import Footer from "../layouts/footer/Footer";
import ThemeProvider from "../theme";
import Pinned from "../pages/KnowledgeManagement/Pinned";
import TagsCloud from "../pages/KnowledgeManagement/TagsCloud";
import RecentFiles from "../pages/KnowledgeManagement/RecentFiles";
import DashboardTrash from "../pages/KnowledgeManagement/DashboardTrash";
import TagAttachedFolder from "../pages/KnowledgeManagement/TagAttachedFolder";
const windowObject = window;

export function PrivateComponent() {
  let response = [
    {
      url: "/",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <DashboardKM />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/folder/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <DashboardKM />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/tags",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <TagsCloud />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/pinned",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Pinned />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/recentfile",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <RecentFiles />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/trash",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <DashboardTrash />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/tags-folder/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <TagAttachedFolder />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
  ];
  return response;
}
