import { Box, Grid, MenuItem, InputLabel, FormControl, Select, Autocomplete, TextField } from '@mui/material'
import React, { useContext, useMemo } from 'react';
import MyContext from './Mycontext';
import category from './DrawerDialogKM/category.json'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Popper from '@mui/material/Popper'

const DashboardFilter = () => {
    const methodsAndState = useContext(MyContext);
    const categoryListData = useSelector((state) => (state.category));
    const transformedArray = [];
    useMemo(() => {
        methodsAndState.filterCategory.forEach(item => {
            item.subCategory.forEach(subCatItem => {
                transformedArray.push({
                    id: subCatItem.id,
                    subCategoryName: subCatItem.subCategoryName,
                    description: subCatItem.description,
                    sorting: subCatItem.sorting,
                    category: subCatItem.category.map(cat => ({
                        id: cat.id,
                        categoryName: cat.categoryName,
                        slug: cat.slug,
                        sorting: cat.sorting,
                        selectType: cat.selectType,
                        created_at: cat.created_at,
                        updated_at: cat.updated_at,
                        pivot: {
                            sub_category_id: subCatItem.id,
                            category_id: cat.id
                        }
                    }))
                });
            });
        });
    }, [])
 
    return (
        <Box sx={{ display: 'flex' }}>
            {/* {category.map((d, i) => ( */}
            <Box sx={{width:'50rem'}}>
                <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {categoryListData?.categoryList.length && categoryListData.categoryList.map((d, i) => (
                    // {methodsAndState.categoryList.map((d, i) => (
                        <Grid key={i} item xs={12} sm={6} md={6} lg={6} >
                            <Autocomplete
                                size="small"
                                fullWidth
                                multiple={
                                    d.selectType == "1" ? true : false
                                }
                                // limitTags={5}
                                defaultValue={transformedArray.filter(
                                    (item) => {
                                      if (item.category[0].id == d.id) {
                                        return d.subCategory;
                                      }
                                    }
                                  )}
                                onChange={(event, value) => methodsAndState.onChangeSelectCategory(event, value, d)}
                                options={d.subCategory}
                                getOptionLabel={(option) => option.subCategoryName}
                                renderInput={(params) => (
                                    <TextField {...params} label={d.categoryName} placeholder={d.categoryName} />
                                    // <TextField sx={{height:"6rem", overflow:"auto"}} {...params} label={d.categoryName} placeholder={d.categoryName} />
                                )}
                               
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default DashboardFilter;