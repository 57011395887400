import { Box, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import excel from '../../assets/images/KM-Manager/excel.svg';
import folder from '../../assets/images/KM-Manager/FolderFillednoicon.svg';
import pdf from '../../assets/images/KM-Manager/pdf.svg';
import word from '../../assets/images/KM-Manager/word.svg';
import svg from '../../assets/images/KM-Manager/svg.svg';
import png from '../../assets/images/KM-Manager/png.svg';
import jpg from '../../assets/images/KM-Manager/jpg.svg';
import jfif from '../../assets/images/KM-Manager/jfif.svg';
import ppt from '../../assets/images/KM-Manager/ppt.svg';
import openDownload from './openDownload';
import MyContext from './Mycontext';
import { useTheme } from '@mui/material/styles';
const ImageType = (props) => {
    const { downloadFile } = openDownload();
    const theme = useTheme();
    const methodsAndState = useContext(MyContext);
    const [openText, setOpenText] = useState('Open');
    const [downaloadText, setDownloadText] = useState('Download');
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isXl = useMediaQuery(theme.breakpoints.down('xl'));
    const getExtension = (filename) => {
        const parts = filename?.split('.');
        return parts != undefined ? parts[parts.length - 1] : "";
    };
    const fileType = props?.data?.type || props?.data?.mediaType || getExtension(props?.data?.name);
    const onHandlerDownloadOpen = (data) => {
        if(props.isPinned && props.data.deletedAt){
            return;
        }
        if (props.data.type != 'directory') {
            let propData = props.data;
            if(props.isRecentFiles){
                propData = {...propData,id: propData.mediaId};
            }
            downloadFile(data, propData, setOpenText, setDownloadText);
        }
    };
    const folderIcon = {
        width: !isLg || !isXl ? "3.48rem" : '2.48rem',
        height: !isLg || !isXl ? "2.78rem" : '1.78rem',
        marginLeft:!isLg || !isXl ? '-10px':'5px'
        // marginLeft:!isLg || !isXl ? '-10px':'8px'
    }
    const pptIcon = {
        width: !isLg || !isXl ? "3.68rem" : '2.68rem',
        height: !isLg || !isXl ? "2.50rem" : '1.50rem',
        marginLeft:!isLg || !isXl ? '-19px':'0px'
        // marginLeft:!isLg || !isXl ? '-19px':'3px'
    }
    const fileIcon = {
        width: !isLg || !isXl ? "3.68rem" : '2.68rem',
        height: !isLg || !isXl ? "3.06rem" : '2.06rem',
        marginLeft:!isLg || !isXl ? '-17px':'0px'
        // marginLeft:!isLg || !isXl ? '-17px':'5px'
    }



    return (
        <Box>
            {props.data.folderIcon ? (
                <img
                    style={fileIcon}
                    src={props.data.folderIcon.filePath}
                    alt="folder-Icon"
                    onError={(e) => { e.target.src = folder; }}
                />
            ) : (
                <Box onClick={() => onHandlerDownloadOpen('open')}>
                    {fileType.toLowerCase() === 'pdf' ? (
                        <img style={fileIcon} src={pdf} alt="PDF" />
                    ) : fileType.toLowerCase() === 'json' ? (
                        <img style={fileIcon} src={excel} alt="excel" />
                    ) : fileType.toLowerCase() === 'docx' ? (
                        <img style={fileIcon} src={word} alt="word" />
                    ) : fileType.toLowerCase() === 'png' ? (
                        <img style={fileIcon} src={png} alt="png" />
                    ) : fileType.toLowerCase() === 'svg' ? (
                        <img style={fileIcon} src={svg} alt="svg" />
                    ) : fileType.toLowerCase() === 'jpg' ? (
                        <img style={fileIcon} src={jpg} alt="jpg" />
                    ) : fileType.toLowerCase() === 'xlsx' ? (
                        <img style={fileIcon} src={excel} alt="excel" />
                    ) : fileType.toLowerCase() === 'csv' ? (
                        <img style={fileIcon} src={excel} alt="excel" />
                    ) : fileType.toLowerCase() === 'xls' ? (
                        <img style={fileIcon} src={excel} alt="Excel" />
                    ) : fileType.toLowerCase() === 'ppt' || fileType.toLowerCase() === 'pptx' ? (
                        <img style={pptIcon} src={ppt} alt="PPT" />
                    ) : (
                        <img style={folderIcon} src={folder} alt="File" />
                        // <img className={folderIcon} src={folder} alt="File" />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ImageType;