import axios from "axios";
// import {resetPasswordApi} from '../../_constants/api.constant'
// import {getAppToken,getSelfToken} from '../../_helper/secureToken'

export async function changePassword(postData){
    const response=await axios.post(
        process.env.REACT_APP_BASEURL+ "api/v2/change-password",
                            postData,
                        
                        ).then(function(response) {
                            console.log(" user update success response");
                            console.log(response);
                            return response;
                        }).catch(function (error) {
                            console.log(error);
                            console.log(error.response);
                            return error.response;
                        });

    return response;
}