import React from 'react'
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import HelpIcon from '@mui/icons-material/Help';
import ShareIcon from '../../assets/images/share.svg'

import { Link } from 'react-router-dom';

const SettingDrawer = ({ parentId }) => {

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };


    const itemsList = [
        {
            label: "My Profile",
            icon: <PersonIcon fontSize='small' />,
            path: "/Profile"
        },
        {
            label: "Change Password",
            icon: <LockIcon fontSize='small' />,
            path: "/ChangePassword"
        },
        {
            label: "Help Center",
            icon: <HelpIcon fontSize='small' />,
            path: "/HelpCenter"
        },
    ];


    return (
        <Box>
            <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer-sm)', lg:'var(--width-drawer-sm)'}}}>
                <Stack sx={{ backgroundColor: "var(--color-lightblue)", mb: 'var(--equal-hw1)' }}>
                    <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Settings</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                                    <CloseIcon sx={{ color: 'var(--color-black)' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>

                <List>
                    <Box sx={{ marginBottom: "var(--margin-small)" }}>
                        {itemsList.map((text, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton
                                    className='settings-list'
                                    key={text.label}
                                    sx={{ display: 'flex', mb: 'var(--equal-hw1)', ':hover': { bgcolor: 'var(--color-lightblue)', transition: '.5s ease' } }}
                                    onClick={handleDrawerClose}
                                    component={Link}
                                    to={text.path}>
                                    <ListItemIcon className='settings-icon' sx={{ minWidth: 'var(--equal-hw2)' }}>
                                        {text.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant='body1' color='var(--color-black)'>{text.label}</Typography>} />
                                    <ListItemIcon className='redirect-icon' sx={{ minWidth: '2rem' }}>
                                        <img src={ShareIcon} alt='redirect-icon' />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </Box>
                </List>
            </Box>
        </Box>
    )
}

export default SettingDrawer