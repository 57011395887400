import axios from "axios";
// import {forgotApi} from '../../_constants/forgotPassword.constants'
// import {getAppToken,getSelfToken} from '../../_helpers/auth/secureToken'

export async function forgetPassword(postData){
   

    let prarmTempObj={};
    // prarmTempObj.app=getAppToken();

    

    const response=await axios.post(
        process.env.REACT_APP_BASEURL+'api/v2/password/email',
        postData,
                {
                   
                }
        ).then(function(response) {
           
        return response;
        }).catch(function (error) {
        console.log('sandip');
        console.log(error.response);
        return error.response;
        });
    return response;
}