import React, { useContext } from 'react';
import DashboardGridView from './DashboardGridView';
import DashboardTreeView from './DashboardTreeView';
import MyContext from './Mycontext';
import { Box } from '@mui/material';

const DashboardGridandTree = ({isTrash = false, isPinned = false}) => {
    const methodsAndState = useContext(MyContext);
    return (
        <Box>
            {methodsAndState.showGridView && <DashboardGridView isPinned={isPinned} isTrash={isTrash} />}
            {methodsAndState.showTreeView && <DashboardTreeView isPinned={isPinned} isTrash={isTrash} />}
        </Box>
    );
};

export default DashboardGridandTree;