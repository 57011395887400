export const userPermissions = {
    KM: 'Knowledgemanager',
    KM_FILE: 'Knowledgemanager.File',
    KM_FILE_OPEN: 'Knowledgemanager.File.Open',
    KM_FILE_CREATE: 'Knowledgemanager.File.Create',
    KM_FILE_RENAME: 'Knowledgemanager.File.Rename',
    KM_FILE_EDIT: 'Knowledgemanager.File.Edit',
    KM_FILE_MOVE: 'Knowledgemanager.File.Move',
    KM_FILE_DELETE: 'Knowledgemanager.File.Delete',
    KM_FOLDER: 'Knowledgemanager.Folder',
    KM_FOLDER_OPEN: 'Knowledgemanager.Folder.Open',
    KM_FOLDER_CREATE: 'Knowledgemanager.Folder.Create',
    KM_FOLDER_RENAME: 'Knowledgemanager.Folder.Rename',
    KM_FOLDER_DELETE: 'Knowledgemanager.Folder.Delete',
    KM_FILE_RESTORE: 'Knowledgemanager.File.Restore',
    KM_FILE_PERMANENT_DELETE: 'Knowledgemanager.File.PermanentDelete'
}